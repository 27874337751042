 /* Prices Page styles  */

 .prices-section {
  height: 100%;
  width: 100%;
  display: block;
  position: relative;
  /* display: flex; */
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3rem 1rem 7rem 1rem;
  margin: 0;
  /* background-color: #e9e9e9; */
}

.prices-title {
  width: 80%;
  margin: 0px auto 0 auto;
  padding: 0px 0 10px 0;
  font-size: 25px;
  color: #8c8684;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.price-logo{
  direction: rtl;
}
.price-logo > img{
    padding: 0 5px;
    margin-top: -8px;
}
.price-step-title{
  width: 80%;
    margin: 0 auto;
    padding: 10px 0 0px 0;
    font-size: 25px;
    color: #8c8684;
    text-align: right;
}
.call-to-plans{
  color: #F39422 !important;
  border-bottom: 1px solid #F39422;
}
.back-btn {
  background: url('/src/assets/images/arrow-left.svg');
  background-repeat: no-repeat;
  position: relative;
  left: 10%;
  /* top: 2.5%; */
  width: 46px;
  outline: none;
  height: 46px;
  /* border: 2px solid #8c8684; */
  /* border-radius: 25px; */
  /* margin: 10px */
}
/* .back-btn:hover{
   background-color: #f7a43e;
   border: 2px solid #f7a43e;

} */
.cards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* padding: 40px 0px 40px 0px ; */
    width: 100%;    
    margin: 0 auto;
  }
  
  .card {
    background: #fff;
    color: hsl(233, 13%, 49%);
    border-radius: 30px;
    margin: 50px;
    /* margin: 0 auto; */
    position: relative;
    cursor: pointer;
    transition: 0.3s;
    /* display:none !important; */
  }
  /* .picked {
    border: 2px solid #333;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }
  .clicked{
    transform: scale(1.5);
  } */
  .card:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transform: scale(1.1);
}
  .cards .card {
    background-color: #fff;
    color: #9c9b9b;
    display: flex;
    margin: 0 auto;
    align-items: center;
    /* transform: scale(1.1); */
    z-index: 1;
    margin: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .card ul {
    display: flex;
    width: 225px;
    /* height: 375px; */
    height: 195px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
  .card ul li {
    list-style-type: none;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
  }
  .card ul li:first-child {
    padding: 30px 10px;
    border-radius: 30px 30px 0 0;
    font-size: 2.2rem;
    /* height: 100%; */
}
  .card ul li.price {
    font-size: 3rem;
    color: hsl(232, 13%, 33%);
    /* padding-bottom: 2rem; */
  }
  .card.shadow {
    box-shadow: -5px 5px 15px 1px rgba(0, 0, 0, 0.1);
  }
  .promo-bar > p{
    padding: 7px 0 13px 0;
    line-height: 18px;
  }
  .promo-bar > p > span > strong {
    padding: 0px 3px;
  }

  .card .price {
    color:  #9c9b9b;
  }
  /* .card:hover {
    transform: scale(1.06) !important;
    color: #000 !important;
    cursor: pointer;
  } */

  .card .cardBtn {
    margin: 0 auto;
    height:2.9rem;
    width: 13.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    color: #fff !important;
    outline: none;
    border: 0;
    background-color: cornflowerblue;
  }
  .cardBtn > a {
    color: #fff !important;
    font-size: 18px;
    text-decoration: none;
  }
  .card .active-btn {
    background: #fff;
    color: hsl(237, 63%, 64%);
  }
 
 #basic, .bottom-bar{
  justify-content: left !important;
  width: 70% !important;
  font-size: 18px;
  margin: 5px 0;
 }
 #basic{
  margin-top: 15px;
  font-size: 30px !important;
 }
  .card .pack {
    font-size: 1.1rem;
    color: #fff;
    background-color: cornflowerblue;
  }
  .card:nth-child(1) > ul > .pack , .card:nth-child(1) > ul > li > .cardBtn {
    background:#6bcdb2 !important;
  }
 .card:nth-child(1) > ul > li > .reg-field > .apply-promo {
    color:#6bcdb2 !important;
  }

  .card:nth-child(2) > ul > .pack , .card:nth-child(2) > ul > li > .cardBtn {
    background: #F39422 !important;
  }
.card:nth-child(2) > ul > li > .reg-field > .apply-promo {
    color: #F39422 !important;
  }

  .card:nth-child(3) > ul > .pack , .card:nth-child(3) > ul > li > .cardBtn {
    
    background: #4d2b85 !important;
  }
.card:nth-child(3) > ul > li > .reg-field > .apply-promo {
 
    color: #4d2b85 !important;
  }

  .card:nth-child(4) > ul > .pack , .card:nth-child(4) > ul > li > .cardBtn {
    background: #e84e1b !important;
  }
.card:nth-child(4) > ul > li > .reg-field > .apply-promo {
    color: #e84e1b !important;
  }
  .card:nth-child(5) > ul > .pack , .card:nth-child(5) > ul > li > .cardBtn {
    background: sandybrown !important;
  }
.card:nth-child(5) > ul > li > .reg-field > .apply-promo {
    color: sandybrown !important;
  }
  .card:nth-child(6) > ul > .pack , .card:nth-child(6) > ul > li > .cardBtn {
    background: mediumorchid !important;
  }
.card:nth-child(6) > ul > li > .reg-field > .apply-promo {
    color: mediumorchid !important;
  }

/* price steps checkbox */
  .price-step > form > .answers > div > label {
        font-weight: normal;
        color: #999999;
  }
  .ckbox-prices:checked,
  .ckbox-prices:not(:checked) {
      position: absolute;
      left: -9999px;
  }
  .ckbox-prices:checked + label,
  .ckbox-prices:not(:checked) + label
  {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #666;
      align-items: flex-start !important;
  }
  .price-step .answers input[type=radio] + label {
    width: auto !important;
    font-size: 25px !important;
    top: 5px;
}
.price-step .answers {
  padding-top: 45px;
  justify-content: space-evenly !important;
  padding-bottom: 20px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto !important;
}

  .ckbox-prices:checked + label:before,
  .ckbox-prices:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid #ddd;
      border-radius: 100%;
      background: #fff;
  }
  .ckbox-prices:checked + label:after, .ckbox-prices:not(:checked) + label:after {
      content: '';
      width: 20px;
      height: 20px;
      background: #f49422;
      position: absolute;
      top: 0px;
      left: 0px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
  }
  .ckbox-prices:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
  }
  .ckbox-prices:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
  }
  .prices-btn{
    width: 150px !important;
    display: inline-flex !important;
    justify-content: space-around;
    margin: 40px 20px 0 20px;
  }
  .price-step > form > center button:first-child{
    background-color: transparent;
    color: #F39422;
    border: 1px solid #F39422;
  }
  .rounded-checkbox label:before {
    margin-left: -1.3em;
    position: absolute;
    top: unset;
}
  /* Discount ribbon */
  .ribbon {
    width: 188px;
    height: 188px;
    position: absolute;
    top: -8px;
    right: 0px;
    overflow: hidden;
    /* text-transform: uppercase; */
    color: #fff;
    font-size: 14px;
    border-radius: 20%;
  }
  .ribbon:before,
  .ribbon:after {
    content: "";
    position: absolute;
  }
  .ribbon:before {
    width: 40px;
    height: 8px;
    right: 70px;
    background: #4a0303;
    border-radius: 8px 8px 0 0;
}
/* .ribbon:after {
  width: 8px;
  height: 40px;
  right: 0;
  top: 70px;
  background: #4a0303;
  border-radius: 0 8px 8px 0;
} */
.ribbon div span {
  width: 165px;
  height: 60px;
  line-height: 40px;
  position: absolute;
  top: 0px;
  right: -50px;
  z-index: 2;
  overflow: hidden;
  transform: rotate(45deg);
  background: red;
  background: linear-gradient(#f70505 0%, #8f0808 100%);
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 22px 20px -20px;
}
.ribbon > div > div {
  word-wrap: break-word;
  width: 80px;
  right: -7px;
  text-align: center;
  position: absolute;
  line-height: 16px;
  z-index: 5;
  direction: ltr;
  margin-top: 22px;
  transform: rotate(45deg);
}
/* PROMO */
.promo-label {
  transition: all 0.3s ease;
  pointer-events: none;
  position: absolute;
  background-color: #fff;
  top: 10px ;
  left: 10px !important;
  cursor: text;
  z-index: 3;
  color: #A9A9A9;
  font-weight: normal;
  font-size: 15px !important;
  padding: 0 5px !important;
}
.reg-field .promo-input {
  padding: 5px 18px !important;
}

.bottom-bar > .form-group {
  margin-top: 0 !important;
}
.apply-promo {
  color: #F39422;
  font-size: 13px;
  background: #fff;
  border-radius: 2px;
  cursor: pointer;
  padding: 4px;
  transition: all 0.4s cubic-bezier(0.9, 0.1, 0.1, 0.9);
  width: auto;
  margin-bottom: 5px;
  position: absolute;
  z-index: 5;
  right: 8px;
  top: 7px;
}
.fa-tag{
    color: gainsboro;
    transform: rotate(90deg);

}
.prices-section > div > select {
  text-align: center;
}
.price_amount{
    display: flex;
    align-items: baseline !important;
    justify-content: center !important;
}
.price_type{
  font-size: 14px;
  margin: 0 5px;
}

/* new cards */

/* .cards{
  margin-top: 6rem;
} */
.cards > .tab-content{
  display: flex;
}
.cards .nav-tabs .nav-item .nav-link {
  width: 170px;
  font-size: 20px; 
  background-color: #fff; 
  padding: 0px; 
  border: none; 
  border-radius: 20px 20px 0 0;
  height: 50px;
}
.cards .nav-tabs .nav-item .active {
  background-color: #fff;
  position: relative;
  z-index: 15;
  border-radius: 20px 20px 0 0;

}
.cards .nav-tabs .nav-item .active{
  /* box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; */
}
.cards > .nav-tabs > .nav-item{
  margin: 0 4px;
  border-bottom: none;
}
.p-card-container{
  background-color: #fff;
  border-radius: 20px;
  border: 2px solid #e7e7e7;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 3px;
  margin: 20px;
  transition: all 400ms;
  position: relative;
}
/* .p-card-container:hover{
  transition: all 400ms;
  transform: scale(1.1);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
} */
.p-card-container:nth-child(2) .p-card-plan{
  color: #662d91;
}
.p-card-container:nth-child(2) > ul > li > span{
  /* background-color: #662d91; */
}
.p-card-container:nth-child(2) .p-card-filled{
  background-color: #662d91;
}
.p-card-plan{
  text-align: center;
  font-size: 3rem;
  color: #4eb6a2;
  font-weight: bold;
  padding: 4rem 6rem 1rem 6rem;
}
.p-card-filled{
  width: 100%;
  background-color: #4eb6a2;
  color: #fff;
  text-align: center;
  padding: 0.5rem 0 1rem 0;
  height: 120px;
}
.p-card-filled > p:nth-child(1){
  font-size: 2.1rem;
  margin: 0;
}
.p-card-filled > p:nth-child(2){
  font-size: 2.5rem;
  text-shadow: 2px 0px 1px rgba(0, 0, 0, 0.54);
  margin: 0;
}
.p-card-filled > p:nth-child(2) > span{
  font-size: 4rem;
}
.p-card-container > ul {
  /* padding: 2.5rem 2.5rem 1.2rem 2.5rem; */
  margin-bottom: 0;
}
.p-card-container > ul > li{
  display: flex;
  /* margin: 10px 0; */
  align-items: center;
}
.p-card-check-container{
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.tab-pane:nth-child(1) .p-card-container ul .p-card-check-container{
  background-color: #e8e5f0;
}
.tab-pane:nth-child(2) .p-card-container ul .p-card-check-container{
  background-color: #edf8f4;
}
.p-card-check-items{
  display: flex;
  margin: 3px 0;
  padding: 0 20px;
  align-items: baseline;
}
.p-card-check-items p{
  margin: 0 10px;
  font-size: 1.9rem;
  color: #666666;
}
.p-card-check-items span{
  /* background-color: #4eb6a2; */
  border-radius: 0px;
  opacity: 0.7;
  /* width: 20px;
  height: 20px; */
}
.check-mark {
    width: 7px;
    height: 13px;
    border: 2px solid #fff;
    border-left: 0;
    border-top: 0;
    transform: rotate(45deg);
    display: block;
    margin-left: 7px;
    margin-top: 4px;
}
.p-card-view-btn{
  background-color: #F39422;
  color: #fff;
  display: flex;
  border-radius: 25px;
  padding: 10px 0;
  font-size: 1.7rem;
  justify-content: center !important;
  /* margin-top: 40px !important; */
  cursor: pointer;
  transition: all 400ms;
  margin: 20px auto;
  width: 60%;
}

.p-card-view-btn:hover{
  color: #fff;
  background-color: #e08516;
  box-shadow: inset 0px 1px 5px #e08516,
              inset 1px -1px 5px #e08516,
              2px 2px 10px #e08516;
  transform: translateY(-3px);
  text-shadow: 0px 0px 25px #fff;

}
.p-card-view-btn:focus{
  transform: translateY(3px);
  transition: all 400ms;
  transform: scale(0.9);
}

.p-card-choosing {
  padding: 20px 20px 5px 20px;
}
.p-card-choosing > p{
  color: #b3b3b3;
  font-size: 1.8rem;
}
.p-card-label {
  /* display: block; */
  /* margin: 20px 0 0;
  border-bottom: 1px solid green; */
}
/* .p-card-label:hover {
  text-decoration: underline;
} */
.p-card-top-icon{
    position: absolute;
    border-radius: 50px;
    background-color: #fff;
    margin-top: -50px;
    left: 40%;
    padding: 20px;
    width: 90px;
    height: 90px;
}
.p-card-top-icon img{
  display: block;
  margin: 0 auto;
}
.p-card-tab > p {
  position: relative;
  z-index: 10;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 0;
  padding: 0 5px;
}
.p-card-tab-icon {
  position: absolute;
  border-radius: 50px;
  background-color: #fff;
  margin-top: -50px;
  left: 33%;
  padding: 10px;
  width: 70px;
  height: 70px;
}
.nav-item:nth-child(1) button .p-card-tab .p-card-tab-icon img{
  width: 20px;
}
.nav-item:nth-child(2) button .p-card-tab .p-card-tab-icon img{
  width: 30px;
}
.p-card-tab-icon img{
display: block;
margin: 0 auto;
}
.p-card-input {
  position: absolute;
  left: -999em;
}
.p-card-ckb{
  margin-bottom: 15px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: baseline;
  position: relative;
}

.shader{
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
}
.diabled-option{
  opacity: 0.5;
}

.p-card-price{
  color: #F39422;
  font-size: 1.25rem;
}
.p-card-price > strong{
  font-size: 19px;
}
.p-card-ckb span{
  padding: 0 25px;
  color:#999999;
}
.p-card-ckb label {
  color: #999999;
}
label[class="p-card-labeling"]{
  border: none !important;
  color: #999999 !important;
  margin-bottom: 0;
  font-weight: bold !important;
  display: flex !important;
}
label[class="p-card-labeling"] p{
  position: absolute;
  z-index: 10;
  margin-top: -25px;
  font-size: 13px;
  margin-left: 8px;
  color: #fff;
}
label[class="p-card-labeling"] span{
  padding: 0 5px;
}
.cards > .nav-tabs{
  display: none;
}
.cards > .tab-content>.tab-pane {
  display: block;
}
.p-card-radios .p-card-radio[type=radio].ckb:checked + label::before {
  border-color: #F39422 !important;
  background-color: #F39422 !important;
  box-shadow: none!important;
  transition: all 0.3s;
  box-shadow: none !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e") !important;
}

.p-card-radios .p-card-radio[type=radio].ckb + label::before {
  transition: all 0.3s;
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  flex-grow: 0;
  border: 2px solid #c9c9c8 !important;
  border-radius: 100%;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  margin: 20px 5px;
}
.p-card-radios .p-card-radio[type=radio].ckb:checked + label:hover{
  border-color: unset !important;
  background-color: transparent !important;
  box-shadow: none !important;
  transition: all 0.3s;

}
.p-card-radios .p-card-radio[type=radio].ckb:checked + label::before{
  opacity: 1;
}
.p-card-radios .p-card-radio[type=radio].ckb:checked + label::before, 
.p-card-radios .p-card-radio[type=radio].ckb:checked + label:hover{
  background-color:#579683;
  transition: background-color .2s ease-in, color .2s ease-in;
}
.p-card-radios .p-card-radio[type=radio].ckb:checked + label {
  transition: all 0.3s;
  /* border-color: unset !important; */
  background-color: transparent !important;
  color: #fff;
}
.p-card-radios .p-card-radio[type=radio].ckb + label::before{
  opacity: 1;
}
.p-card-radios .p-card-radio[type=radio].ckb {
  z-index: -1;
  opacity: 0;
  display: none;
}
.p-card-radios .p-card-radio[type=radio].ckb + label {
  display: inline-flex;
  align-items: center;
  font-size: 19px;
  font-weight: normal;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  transition: all 0.3s;
  color: #b6b6b3;
  width: 100%;
  height: 30px;
  justify-content: flex-start;
  padding: 0;
}
/* Cards switcher */
.checkbox-wrapper-64{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.9rem;
}
.p-card-offer {
  background-color: #4eb6a2;
  padding: 3px 10px;
  margin: 0 10px;
}
.p-card-swicher{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.9rem;
  margin-bottom: 60px;
}
.p-card-swicher-small{
  flex-direction: column;
  align-items: center;
  gap: 20px;  
}
.checkbox-wrapper-64 input[type="checkbox"] {
  visibility: hidden;
  display: none;
}

.checkbox-wrapper-64 *,
.checkbox-wrapper-64 ::after,
.checkbox-wrapper-64 ::before {
  box-sizing: border-box;
}

/* The switch - the box around the slider */
.checkbox-wrapper-64 .switch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 3.5em;
  height: 1.8em;
  margin: 0 10px;
}

/* Hide default HTML checkbox */
.checkbox-wrapper-64 .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.checkbox-wrapper-64 .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border: 1px solid #000;
  transition: .4s;
  border-radius: 30px;
}

.checkbox-wrapper-64 .slider:before {
  position: absolute;
  content: "";
  height: 1.4em;
  width: 1.4em;
  border-radius: 20px;
  left: 0.18em;
  bottom: 0.16em;
  background-color: #000;
  transition: .4s;
}

.checkbox-wrapper-64 input:checked + .slider {
  background-color: #4eb6a2;
  border: 1px solid #4eb6a2;
}

.checkbox-wrapper-64 input:focus + .slider {
  box-shadow: 0 0 1px #4eb6a2;
}

.checkbox-wrapper-64 input:checked + .slider:before {
  transform: translateX(1.6em);
  background-color: #fff;
}


/* discount label */
.p-price-discount{
  display: flex;
  height: 60px;
  /* width: 180px; */
  font-size: 15px;
  margin: 0 25px;
  position: absolute;
  left: 58%;
}
.p-price-discount-full{
  height: 60px;
  /* width: 180px; */
  font-size: 15px;
  margin: 0 0px;
  position: relative;
  text-align: center;
}
@media(max-width:992px){
  /* discount label */
  .p-price-discount{
    position: relative !important;
    left: initial !important;
  }  
}
.p-discount-left{
  background-color: #d41718;
  border-radius: 10px 0 0 10px;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-weight: bold;
  padding: 5px;
  line-height: 20px;
  text-align: center;
  box-shadow: #cecece 3px 5px;

}
.p-discount-right{
  background-color: #fff;
  border: 1px solid #f4cecf;
  /* border-radius: 0 25px 25px 0; */
  width: 60%;
  text-align: center;
  line-height: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 0px;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 25px;
    /* for Mozila version less than 3.6  */
    -moz-border-radius-topleft: 30px;
    -moz-border-radius-topright: 5px;
    -moz-border-radius-bottomleft: 5px;
    -moz-border-radius-bottomright: 30px;
  /* Shadow */
  box-shadow: #cecece 3px 5px;
}
.p-per-offer{
  color:#fff;
  font-weight: bold;
}
.p-label-circle{
    width: 12px;
    height: 10px;
    background: rgb(233, 233, 233);
    border-radius: 50px;
}

  @media(max-width:1089px){
    /* .back-btn{
      top: 160px;
    } */
  }
  @media(max-width:992px){
    .cards > .nav-tabs{
      display: flex;
      width: 100%;
      justify-content: center;
      border-bottom: none;
    }
   
    .p-card-plan {
      display: none;
  }
 
  /* .nav-item {
    position: relative;
    background-color: #fff;
    z-index: 15;
    border-radius: 20px 20px 0 0;
} */
    .p-card-container {
      background-color: #fff;
      border-radius:28px;
      border-left: 2px solid #e7e7e7;
      border-right: 2px solid #e7e7e7;
      border-bottom: 2px solid #e7e7e7;
      border-top: 0;
      box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;      
      padding: 30px 3px 3px 3px;
      margin: 0 20px 20px 20px;
      transition: all 400ms;
      position: relative;
  }
    .p-card-top-icon{
      display: none;
    }
    .cards > .tab-content>.tab-pane {
      display: none;
  }
  .cards > .tab-content>.active{
    display: block;

  }
    .prices-section{
      /* padding: 0 0 1rem 0; */
    }
    .prices-title {
      width: 95%;
      margin-top: 0 !important;
  }
    .price-step-title {
      padding: 0;
      font-size: 20px;
  }
  .price-logo > img {
    padding: 0 5px;
    margin-top: -8px;
    width: 40px;
}
.price-logo {
  font-size: 19px;
}
.price_desc {
  font-size: 16px !important;
  margin-bottom: 0 !important;
}
  .price-step > form > .answers > div {
    width: 100%;
  }
  /* .back-btn {
    top: 13%;
} */
.p-card-radios .input-checkbox[type=radio].ckb + label {
  font-size: 19px !important;
  padding: 0;
}
.checkbox-wrapper-64{
  margin-bottom: 15px;
  /* width: 232px; */
  justify-content: space-between;
}
.p-card-swicher{
  flex-direction: column;
  margin-bottom: 20px;
}
.checkbox-wrapper-64 .switch {
  height: 1.3em;
  width: 3em;
}
.checkbox-wrapper-64 .slider:before {
  height: 0.9em;
  width: 0.9em;
  bottom: 0.15em;
}

  }
  @media(max-width:768px){
    .prices-text{
      font-size: 20px;
    }
    .price-step .answers input[type=radio] + label {
      font-size: 20px !important;
      top: 5px;
      /* padding: 0 30px 0 30px; */
  }
  .prices-btn{
    margin: 10px auto 5px auto !important;
  }
  .submit-button-auto {
    margin: 2rem auto 2rem;
    font-size: 22px;
}
.white-area {
  padding: 30px 20px 30px 20px;
}
.price-step .answers {
  /* width: 90%; */
}
.price-step > form > center {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-top: 25px;
}
.card ul {
  height: 190px;
}
  .price-step > form > .answers > div {
    width: 100%;
    display: flex;
    /* justify-content: center; */
  }
  .price_desc{
    margin: 15px 5px !important;
  }
  }
  @media(max-width:550px){
    .cards .card {
      flex-basis: 40%;
      /* box-shadow: none; */
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      transform: scale(0.9);
      margin: 1rem 0 0 0 !important;
  }
  }
  @media (max-width: 600px){

    .stripe-container {
        margin: 4rem auto !important;
    }
  }  
  @media(max-width:560px){
    .cards .card {
      flex-basis: 40%;
      margin: 0;
      transform: scale(0.9);
  }
  /* .back-btn {
    top: 7%;
} */

  /* .card ul {
    width: 100%;
  } */
  }
  @media (max-width: 280px) {
    /* .card ul {
      margin: 1rem;
    } */

    .cards {
      margin: 0;
      display: flex;
      flex-direction: column;
    }
  
    .card {
      transform: scale(0.8);
      margin-bottom: 1rem;
    }
    .cards .card {
      transform: scale(0.8);
    }
  }
  
  @media (min-width: 280px) and (max-width: 320px) {
    /* .card ul {
      margin: 20px;
    } */
    .cards {
      display: flex;
      flex-direction: column;
    }
    .card {
      margin-bottom: 1rem;
    }
    /* .cards .card.active {
      transform: scale(1);
    } */
  }
  
  @media (min-width: 320px) and (max-width: 414px) {
    .cards {
      display: flex;
      flex-direction: column;
    }
    .card {
      margin-bottom: 1rem;
    }
    /* .cards .card.active {
      transform: scale(1);
    } */
    /* .prices-section {
      padding-top: 110px;
  } */
  }
  @media (max-width: 320px){
    /* .prices-section {
      padding-top: 100px;
  } */
  .prices-title {
    padding: 40px 0 10px 0;
}
  }
  @media (min-width: 414px) and (max-width: 768px) {
    .card {
      margin-bottom: 1rem;
      margin-right: 1rem;
    }
    /* .cards .card.active {
      transform: scale(1);
    } */
 
  }
  @media (min-width: 768px) and (max-width: 1046px) {
    .cards {
      display: flex;
    }
    .card {
      margin-bottom: 1rem;
      margin-right: 1rem;
    }
    .cards .card {
      transform: scale(1);
    }
  }
  @media(max-width:500px){
    .p-card-radios .input-checkbox[type=radio].ckb + label{
      font-size: 15px !important; 
    }
    .card ul li:first-child {
      font-size: 1.2rem;
  }
  .card ul li.price {
    font-size: 1.5rem;
}
.card .cardBtn {
  width: 9rem;
}

/* new card */
.p-card-plan{
  display: none;
}
.p-card-container {
  padding: 20px 3px 3px 3px;
}
.p-card-filled {
  padding: 10px 0;
}
.p-card-filled > p:nth-child(2) {
  font-size: 20px;
}
.p-card-filled > p:nth-child(2) > span {
  font-size: 35px;
}
.p-card-check-items p {
  font-size: 17px;
}
.p-card-choosing > p {
  font-size: 18px;
}
.p-card-ckb span {
  padding: 0px;
  font-size: 13px;
}
.p-card-filled > p:nth-child(1) {
  font-size: 19px;
}
.p-card-top-icon{
  display: none;
}
.p-card-price {
  font-size: 0.8rem;
}
.p-card-view-btn {
  font-size: 15px;
}
.p-card-choosing {
  padding: 20px 7px 5px 7px;
}
.cards .nav-tabs .nav-item .nav-link {
  width: 120px;
  height: 40px;
}
.p-card-tab-icon {
  margin-top: -38px;
  left: 31%;
  padding: 5px;
  width: 50px;
  height: 50px;
}
  }
 
  @media(max-width:991px) and (min-width:490px){
    /* .back-btn {
      top: 10%;
  } */
  }

  @media(max-width:460px){
    .back-btn{
      /* top:10%; */
        }
  
  } 
@media(max-width:417px){
  .price-step .answers {
    /* width: 90%; */
    display: flex;
    flex-direction: column;
}

}
  
  /* promo {
    position: relative;
    display: block;
    margin: auto;
    width: auto;
    margin: 0;
  }
  promo promo-title {
    padding: 15px;
    display: block;
    width: 100%;
    margin-bottom: 16px;
  }
  promo promo-title promo-code-title {
    display: block;
    float: left;
    text-transform: capitalize;
  }
  
 
  promo .form-group input {
    background: none;
    background: none;
    padding: 8px;
    border: none;
    position: relative;
    color: #2c3643;
    z-index: 1;
    font-weight: 400;
    border-bottom: 1px solid #d5d7d9;
    padding-left: 0;
    box-sizing: border-box;
    display: block;
    font-size: 14px;
    width: 100%;
    outline: none;
    box-shadow: none;
    border-radius: 0;
  }
  promo .form-group input:focus {
    outline: none;
  }
  promo .form-group {
    position: relative;
    margin-top: 0px !important;
    margin: 0;
  } */
  /* promo .form-control-placeholder {
    position: absolute;
    top: 6px;
    z-index: 0;
    transition: all 200ms;
    color: #abafb4;
    font-weight: 300;
    text-transform: capitalize;
  }
  promo .form-control:focus {
    box-shadow: none !important;
    border-color: #000e09 !important;
  }
  promo .form-control:focus + .form-control-placeholder,
  promo .form-control:valid + .form-control-placeholder {
    font-size: 75%;
    transform: translate3d(0, -100%, 0);
    -ms-transform: translate3d(0, -100%, 0);
    -webkit-transform: translate3d(0, -100%, 0);
    -moz-transform: translate3d(0, -100%, 0);
    -o-transform: translate3d(0, -100%, 0);
    opacity: 1;
    outline: none;
    border-top: none;
    border-left: none;
    border-right: none;
    font-weight: 500;
    color: #80868e;
    line-height: 14px;
  }
  promo .form-control:focus + .form-control-placeholder {
    color: #063579;
  }
  promo button {
    position: absolute;
    width: 30%;
    bottom: 0;
    right: 0;
    padding: 8px;
  }
  promo .buttonContainer {
    text-align: center;
    position: absolute;
    bottom: 48px;
    line-height: 0;
    right: 0;
  }
  promo .dsButtonAnim {
    text-align: center;
    display: inline-block;
    font: 18px "Open Sans", "Century Gothic", "arial" semibold;
    text-transform: uppercase;
    color: #fff;
    line-height: 0;
    background: #F39422;
    border-radius: 2px;
    cursor: pointer;
    padding: 18px;
    -webkit-transition: all 0.4s cubic-bezier(0.9, 0.1, 0.1, 0.9);
    transition: all 0.4s cubic-bezier(0.9, 0.1, 0.1, 0.9);
    width: 106px;
    height: 36px;
    margin-right: 16px;
  }
  promo .dsButtonAnim span {
    opacity: 1;
    -webkit-transition: 0.2s 0.4s ease;
    transition: 0.2s 0.4s ease;
  }
  promo .dsButtonAnim.loading {
    background: #fdb813;
    width: 36px;
    height: 36px;
    padding: 0rem;
    border-radius: 50%;
    -webkit-transition: all 0.4s 0.2s cubic-bezier(0.9, 0.1, 0.1, 0.9);
    transition: all 0.4s 0.2s cubic-bezier(0.9, 0.1, 0.1, 0.9);
    -webkit-animation: loadingAnimation 2s 0.48s infinite;
    animation: loadingAnimation 2s 0.48s infinite;
  }
  promo .dsButtonAnim.loading span {
    opacity: 0;
    -webkit-transition: 0.2s ease;
    transition: 0.2s ease;
  }
  promo .dsButtonAnim.success {
    position: relative;
    background: #30b065;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    -webkit-animation: none;
    animation: none;
    -webkit-transition: all 0.4s cubic-bezier(0.9, 0.1, 0.1, 0.9);
    transition: all 0.4s cubic-bezier(0.9, 0.1, 0.1, 0.9);
  }
  promo .dsButtonAnim.success span {
    opacity: 0;
    -webkit-transition: 0.2s ease;
    transition: 0.2s ease;
  }
  promo .dsButtonAnim.success::before,
  promo .dsButtonAnim.success::after {
    content: "";
    display: inline-block;
    position: absolute;
    height: 3px;
    background: #fff;
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    z-index: 10;
    -webkit-animation: showPseudos 0.4s 0.2s cubic-bezier(0.9, 0.1, 0.1, 0.9) forwards;
    animation: showPseudos 0.4s 0.2s cubic-bezier(0.9, 0.1, 0.1, 0.9) forwards;
    opacity: 0;
    left: 45%;
    bottom: 0.54rem;
  }
  promo .dsButtonAnim.success::before {
    width: 10px;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
  promo .dsButtonAnim.success::after {
    margin-left: -2px;
    width: 20px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  promo .dsButtonAnim.error {
    position: relative;
    background: #da3b3b;
    width: 3rem;
    height: 3rem;
    -webkit-animation: none;
    animation: none;
    -webkit-transition: all 0.4s cubic-bezier(0.9, 0.1, 0.1, 0.9);
    transition: all 0.4s cubic-bezier(0.9, 0.1, 0.1, 0.9);
  }
  promo .dsButtonAnim.error span {
    opacity: 0;
    -webkit-transition: 0.2s ease;
    transition: 0.2s ease;
  }
  promo .dsButtonAnim.error::before,
  promo .dsButtonAnim.error::after {
    content: "";
    display: inline-block;
    position: absolute;
    height: 4px;
    width: -5rem;
    left: 50%;
    margin-left: -2.5rem;
    background: #fff;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    z-index: 10;
    -webkit-animation: showPseudos 0.4s 0.2s cubic-bezier(0.9, 0.1, 0.1, 0.9) forwards;
    animation: showPseudos 0.4s 0.2s cubic-bezier(0.9, 0.1, 0.1, 0.9) forwards;
    opacity: 0;
    top: 50%;
    margin-top: -2px;
  }
  promo .dsButtonAnim.error::before {
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
  promo .dsButtonAnim.error::after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  @-webkit-keyframes loadingAnimation {
    0% {
      box-shadow: 0px 0px 0px 0px #fb0;
    }
    100% {
      box-shadow: 0px 0px 0px 1rem rgba(255, 187, 0, 0);
    }
  }
  @keyframes loadingAnimation {
    0% {
      box-shadow: 0px 0px 0px 0px #fb0;
    }
    100% {
      box-shadow: 0px 0px 0px 1rem rgba(255, 187, 0, 0);
    }
  }
  @-webkit-keyframes showPseudos {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes showPseudos {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .apply-promo {
    color: #F39422;
    z-index: 2;
    font-size: 13px;
    background: #fff;
    border-bottom: #F39422 1px solid;
    border-radius: 2px;
    cursor: pointer;
    padding: 4px;
    transition: all 0.4s cubic-bezier(0.9, 0.1, 0.1, 0.9);
    width: auto;
    margin-bottom: 5px;
}

.apply-promo:hover{
  background-color: #F39422;
  color: #fff;
} */