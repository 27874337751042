.notFoundContainer{
    margin-top: -100px;
}
.c{
    text-align: center;
    display: block;
    position: relative;
    width:80%;
    margin:0 auto;
}
._404{
    font-size: 180px;
    position: relative;
    display: inline-block;
    z-index: 2;
    color: #F39422;
    height: 250px;
    letter-spacing: 15px;
}

._2{
    text-align:center;
    display:block;
    position: relative;
    font-size: 20px;
    color: #F39422;
}

.notFoundContainer > hr{
    padding: 0;
    border: none;
    border-top: 5px solid #F39422 ;
    color: #F39422 ;
    text-align: center;
    margin: 0px auto;
    width: 420px;
    height:10px;
    color: #F39422;
    z-index: -10;
}

