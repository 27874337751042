.top-titles{
    display: flex;
    justify-content: space-between;
    color: #606060;
    font-size: 25px;
}
.edit-icon{
    color:#f49422;
    margin: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.fa-pencil{
    margin-right: 10px;
    width: 22px;
    vertical-align: inherit;
}
.profile-icon{
    display: flex;
}
.profile-icon > p{
    margin: 0;
    text-transform: capitalize;
    /* padding: 15px; */
}
.user-img{
    padding:0 0 80px 0;
}
.profile-type-name{
    margin: 0 auto;
    color: #606060;
    width: 82%;
    display: flex;
    justify-content: flex-start;
    font-size: 25px;
    padding-top: 60px;
}
.anchor-link:hover {
    color: #f49422 !important;
}

.add-child > p:hover{
    color:#e78003;
}
.orange-btn:hover{
    color:#e78003;
}
.profile-user-name {
    color: #606060;
    text-align: center;
    font-size: 26px;
    padding:0px 20px 0 20px;
    text-transform: capitalize;
}
.profile-date{
    padding-right: 0px !important;
    width: 33% !important;
    display: inline-block !important;
    padding-left: 5px !important;
}
.profile-field{
    display: flex;
}
.profile-field > label {
    width: 50%;
    font-size: 22px;
    color: #606060;
    font-weight: normal;
}
.profile-field > input {
    width: 50%;
    padding: 8px 28px !important;
}
.profile-field > #mobile{
    padding: 8px 28px !important;
}
.profile-box{
    padding: 2rem 2.5rem !important;
}
.profile-image-container{
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 7%;
}
.user-image{
    height: 100px;
    width: 100px;
}
.user-dob{
    padding: 10px 10px !important;
}
.readonly-field{
    background-color: #6fbba4 !important;
    color: #fff !important;
    border: 1px solid #6fbba4 !important;
    opacity: 3;
    background-image: none;
}
.readonly-field::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff !important;
    line-height: 1.5em;
  }
.eye-icon{
    top: 13px !important;
    right: 15px !important;
}
.profile-eye{
    color: #fff !important;
}
.select-label{
    color: #606060;
    font-size: 22px;
    line-height: 27px;
}
#parent_email.parent_email{
    background-color: #6fbba4 !important;
    color: #fff !important;
    border: 1px solid #6fbba4 !important;
    opacity: 3;
}
#parent_email.parent_email2{
    background-color: #fff !important;
    color: #908f91 !important;
    border: 1px #bebcbc solid !important;

}
@media(max-width:1070px){
    input[type=checkbox].ckb + label, input[type=radio].ckb + label {
        height: 40px;
        width: 100px;
    }
}
@media(max-width:992px){
    .profile-icon {
        display: flex;
        align-items: center;
    }
    .profile-icon > p {
        padding: 0 0 0 5px;
    }
    .top-titles {
        font-size: 22px;
        padding-top: 20px;
        width: 90%;
        margin: 0 auto;
    }
    .profile-box{
        padding: 0 !important;
    }
    .col-md-6{
        margin-right: 0px !important;
        margin-left: 0px !important;
    }
    .profile-type-name {
        width: 90%;
        font-size: 21px;
        padding-top: 30px;
        padding-bottom: 20px;
    }
    .profile-user-name {
        font-size: 23px;
    }
    .orange-btn {
        font-size: 23px;
    }
    .fa-pencil {
        width: 15px;
    }
    .eye-icon {
        top: 10px !important;
        right: 15px !important;
    }
   .profile-icon > img {
        width: 33px;
    }
    .form-select {
        background-size: 11px;
        width: 75px;
    }
    .user-img {
        padding: 0 0 40px 0;
    }
    #mobile {
        padding: 8px 5px 7px 55px !important;
    }
    .reg-field-tel label {
        left: 80px;
    }
    .reg-field-tel label {
        font-size: 17px;
    }
    .flex-group {
        /* flex-direction: column; */
    }
    .reg-input {
        padding: 8px 20px ;
        background-image: none;
    }
}
@media(max-width:700px){
    .profile-field > label {
        font-size: 16px;
    }
    .profile-field > input ,.profile-field > #mobile {
        padding: 8px 8px !important;
    }
    .profile-field > .field-icon{
        right: 10px;
    }
    .select-label{
        font-size: 16px;
    }
}



