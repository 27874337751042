.newsletter-popup {
  display: none;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  transition: all .3s ease;
}
.newsletter-popup .newsletter-popup-container {
  position: relative;
  display: flex;
  flex-flow: column;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  width: 500px;
  transform: scale(0.3);
  transition: all .3s ease;
}
.newsletter-popup .newsletter-popup-container.open {
  transform: scale(1.5);
}
.newsletter-popup .newsletter-popup-container h3 {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
  padding: 10px 0 10px;
  color: #4d5561;
}
.newsletter-popup .newsletter-popup-container h3 i {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #F39422;
  color: #fff;
  margin-right: 10px;
}
.newsletter-popup .newsletter-popup-container p {
  margin: 0;
  padding: 15px 0;
  color: #8d9092;
}
.newsletter-popup .newsletter-popup-container form {
  display: flex;
  padding: 15px 0 10px;
}
.newsletter-popup .newsletter-popup-container form input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  outline: none;
}
.newsletter-popup .newsletter-popup-container form input::placeholder {
  color: #8d9092;
}
.newsletter-popup .newsletter-popup-container form button {
  width: 200px;
  appearance: none;
  background-color: #F39422;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  border: 0;
  cursor: pointer;
}
.newsletter-popup .newsletter-popup-container form button:hover {
  background-color: #F39422;
}
.newsletter-popup .newsletter-popup-container .newsletter-popup-close-btn {
  position: absolute;
  right: 20px;
  top: 20px;
  text-decoration: none;
  color: #ddd;
  font-size: 30px;
  line-height: 20px;
}
.newsletter-popup .newsletter-popup-container .newsletter-popup-close-btn:hover {
  color: #c4c4c4;
}
.newsletter-popup .newsletter-popup-container .newsletter-msg {
  padding: 5px 0;
}
.newsletter-popup.open {
  display: flex;
  background-color: rgba(0, 0, 0, 0.4);
}
/* last version of news letter design */
.img-box { grid-area: newsletter-img; }
.newsletter-text { grid-area: newsletter-text; }
.form-box { grid-area: newsletter-form; }

.newsletter-section > div{
  margin: 0 auto;
  display: grid;
  grid: 'newsletter-text newsletter-text newsletter-text newsletter-text newsletter-img newsletter-img'
        'newsletter-text newsletter-text newsletter-text newsletter-text newsletter-img newsletter-img'
        'newsletter-form newsletter-form newsletter-form newsletter-form newsletter-img newsletter-img'
        'newsletter-form newsletter-form newsletter-form newsletter-form newsletter-img newsletter-img'
        'newsletter-form newsletter-form newsletter-form newsletter-form newsletter-img newsletter-img'
        'newsletter-form newsletter-form newsletter-form newsletter-form newsletter-img newsletter-img';

  /* grid-gap: 20px; */
  grid-auto-flow: column;
  grid-column-gap: 10px;
  grid-auto-columns: 1fr;  
}

.newsletter-section {
  width: 100%;
  background-color: #EDF8F3;
}
.newsletter-section > div {
  width: 80%;
  max-width: 750px;
  margin: 0 auto;
  /* display: flex; */
  padding: 50px 0 50px 0;
  /* flex-wrap: wrap;
  flex-direction: row-reverse; */
}
/* .newsletter-section > div > .img-box{
  display:inline-block;
  width: 50%;
} */
.newsletter-section > div > .img-box > img {
  margin: 0 auto;
  display: block;
  width: 100%;
  max-width: 200px;
  height: 200px;
}
.newsletter-section > div > .form-box{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
/* .whole-form{
  width: 80%;
  display: block;
  margin: 0 auto;
} */
.whole-form > h2 {
  font-size: 40px;
  color: #676767;
}

.newsletter-text {
  font-size: 2rem;
  color: #999999;
  font-weight: 600;
  /* padding-bottom: 20px; */
  margin-bottom: 0;
  display: flex;
  align-items: flex-end;
}
.whole-form > form > .reg-btn {
  width: 100%;
  max-width: 150px;
  margin: 0 20px;
  font-size: 19px;
  background-color: #6DD3A8;
}
.whole-form > form > .reg-btn:hover{
  background-color: #78e7b9;
}
.whole-form > form {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 0 auto;
}
.whole-form > form > .reg-field > label{
  color: #6DD3A8;
  left: 30px;
}
.whole-form > form > .reg-field > .reg-input{
  border: 1px solid #6DD3A8 !important;
  color: #6DD3A8;
}
.whole-form > form > .reg-field > .reg-input::placeholder{
  color: #6DD3A8 !important;
  font-size: 16px !important;
}
.after-sub {
  text-align: center;
  font-size: 1.8rem;
  color: #999999;
}
@media(max-width:992px){
  .newsletter-section > div{
    margin: 0 auto;
    display: grid;
    grid: 'newsletter-text newsletter-text newsletter-text newsletter-text newsletter-text newsletter-text'
          'newsletter-text newsletter-text newsletter-text newsletter-text newsletter-text newsletter-text'
          'newsletter-img newsletter-img newsletter-img newsletter-img newsletter-img newsletter-img'
          'newsletter-img newsletter-img newsletter-img newsletter-img newsletter-img newsletter-img'
          'newsletter-form newsletter-form newsletter-form newsletter-form newsletter-form newsletter-form'
          'newsletter-form newsletter-form newsletter-form newsletter-form newsletter-form newsletter-form';

    /* grid-gap: 20px; */
    grid-auto-flow: column;
    grid-column-gap: 10px;
    grid-auto-columns: 1fr;  
    width: 100%;
}
.newsletter-text{
  text-align: center;
  font-size: 23px;
  font-weight: 700;
  justify-content: center;
}

.newsletter-section > div > .form-box {
  align-items: flex-start;
}
.newsletter-section > div > .img-box {
  width: 100%;
}
.newsletter-section > div > .form-box {
width: 100%;
}
.whole-form {
width: 90%;
margin: 0 auto;
}
.whole-form > form {
width: 100%;
justify-content: center;
margin: 0 auto;
}
.whole-form > form > .reg-btn{
max-width: 100px;
font-size: 16px;
}
.whole-form > h2 {
font-size: 30px;
}
.whole-form > p {
font-size: 20px;
padding-bottom: 20px;
}

}