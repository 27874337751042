.dashboard-container{
    /* margin-top: 120px; */
    padding: 1rem 0rem 0rem 0rem;
}
.dashboard-contents{
    width: 100%;
    margin: 0 auto;
    padding:40px 0;
}

/* summary table */
.summary-table {
    border-collapse: separate;
    border-spacing: 0;
    width: 90%;
    min-width: 350px;
    margin: 0 auto;
    border-radius: 27px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    margin-bottom: 8rem;
    margin-top: -1px !important;
  }
  .hello-title {
    font-size: 25px;
    padding-bottom: 25px;
    width: 85%;
    margin: 0 auto;
    color:  #6d6b6c;
}
  thead {
    color: #c0bebd;
    height: 7rem;
    font-size: 22px;
  }
 
  td{
    width:25%;
    text-align: center;
    padding: 15px 0!important;
  }
  .summary-table tr th,
  .summary-table tr td {
    /*border-right: 1px solid #ebeaea;
    border-bottom: 1px solid #ebeaea;*/
    padding: 10px;
  }

  .summary-table tr td:first-child,
  .summary-table tr th:first-child {
    border-left: 1px solid #c2c2c1;
  }

  .summary-table tr td:last-child,
  .summary-table tr th:last-child {
    border-right: 1px solid #c2c2c1;
  }

  .summary-table tr:last-child td{
    border-bottom: 1px solid #c2c2c1;
  }  

  .summary-table tr:not(last-child) td{
    border-bottom: 1px solid #c2c2c1;
    font-size: 22px;
  }  

  .summary-table tr th {
    background: #f5f5f5;
    border-top: 1px solid #c2c2c1;
    text-align: center;
    font-size: 25px;
    font-weight: normal;
  }
  
  /* top-left border-radius */
  .summary-table tr:first-child th:first-child {
    border-top-left-radius: 27px;
  }
  
  /* top-right border-radius */
  .summary-table tr:first-child th:last-child {
    border-top-right-radius: 27px;
  }
  
  /* bottom-left border-radius */
  .summary-table tr:last-child td:first-child {
    border-bottom-left-radius: 27px;
  }
  
  /* bottom-right border-radius */
  .summary-table tr:last-child td:last-child {
    border-bottom-right-radius: 27px;
  }

  .summary-table ul {
    margin:0px;
    display: grid;
  }
  .course-dashboard{
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 80%;
  }
  .course-name-dashboard {
    font-size: 24px;
    color: #6d6b6c;
    display: inline-block;
    padding: 0 5px;
    text-align: center;
    width: 100%;
}
  .course-img-dashboard {
    display: inline-block;
    width: 100%;
    max-width: 100px;
    padding: 10px;
}

.table-btn {
  margin: 13px auto;
  border-radius: 25px;
  border: 1px solid #c2c2c1;
  color: #F39422;
  font-size: 22px;
  width: 220px;
  padding: 5px;
}


.table-d-cell {
  color: #6d6b6c;
}
.doughnutchart{

    display: block;
    box-sizing: border-box;
    height: 80px;
    /* width: 350px; */

}
.item-title{
  opacity: 0;
}
.playIcon:hover + .item-title {
  opacity: 1;
  transition: background-color .2s ease-in, color .2s ease-in;

}
.row-p > center {
  font-size: 25px;
}
.active-child-table{
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 20px 50px 20px;
  border-color: transparent transparent #f1f1f1 transparent;
  line-height: 0px;
  position: absolute;
  _border-color: #000000 #000000 #f1f1f1 #000000;
  _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
}
.table-btn >  a {
  color :#F39422;
}

/* Bubble triangle slider tab */

.bubble {
  /* background: #fff; */
  /* float: left; */
  /* border: 1px solid #d0cfd2; */
  /* padding: 15px; */
  /* box-shadow: 0px 0px 2px #000; */
  /* border-radius: 3px; */
  /* height: auto; */
  /* width: 324px; */
  /* margin: 20px 150px; */
  position: relative;
}

.bubble:after {
  content: '';
  position: absolute;
  border: 1px solid #c2c2c1;
}
.bubble:before {
  content: '';
  position: absolute;
  border: 1px solid #c2c2c1;
}
/* .bubble.top:after {
  border-color: #f5f5f5 transparent;
  border-width: 0 13px 12px 13px;
  top: -11px;
  left: 10%;
}
.bubble.top:before {
  border-color: #999 transparent;
  border-width: 0 13px 11px 13px;
  top: -11px;
  left: 10%;
} */
.bubble.top:after {
  border-color: #f5f5f5 transparent;
  border-width: 0 25px 40px 25px;
  bottom: -35px;
  left: 45%;
}
.bubble.top:before {
  border-color: #999 transparent;
  border-width:0 25px 41px 25px;
  bottom: -34px;
  left:45%;
}
.dashboard-contents > .gray-bg {
  margin-top: 0;
}
.swiper-button-prev2
{
    background-image: linear-gradient(to left, rgba(255,0,0,0), #fd8b02) !important;
    background-color: transparent !important;
    right: -90% !important;
    width: 160px !important;
    left: -10%;
    height: 100% ;
    top: -5px !important;
    opacity: 45% !important;
    transition:  .2s ease-in;
}
.swiper-button-next 
{
    background-image: linear-gradient(to right, rgba(255,0,0,0), #fd8b02) !important;
    background-color: transparent !important;
    /* left: 92.5% !important; */
    width: 160px !important;
    /* height: 130% !important; */
    top: -5px !important;
    opacity: 45% !important;
    transition:  .2s ease-in;
}
.table-anchor{
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin: 5px auto;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.card-swiper{
  /* width: 100% !important;
  margin: 0 auto;
  display: block; */
}
@media(max-width:992px){

.summary-table {
  min-width: unset;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-bottom:0;
}
.summary-table tr th {
  font-size: 15px;
}
.bubble.top:after {
  left: 40%;
}
.bubble.top:before {
  left: 40%;
}
.bubble:before {
  border:none;
}
.dashboard-contents {
  padding: 40px 0 20px 0;
}
.dashboard-contents > .gray-bg {
  margin-top: 3rem;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 85px 0 85px 0;
}
.summary-table tr td:first-child,
.summary-table tr th:first-child {
  border-left:none;
}

.summary-table tr td:last-child,
.summary-table tr th:last-child {
  border-right: none;
}

.summary-table tr:last-child td{
  border-bottom: none;
}  

.summary-table tr:not(last-child) td{
  border-bottom: none;
}  

.summary-table tr th {
  border-top: none;
}
.swiper-button-next , .swiper-button-prev {
  width: 50px !important;
}
.course-dashboard{
  display: block;
  width: 100%;
}
.course-name-dashboard {
  display: block;
  color: #3a3a3a;
  font-size: 17px;
}
.course-img-dashboard {
  display: block;
  margin: 0 auto;
  width: 75px;
  padding: 10px 10px 0px 10px;
}
tbody > tr{
  /* border-bottom: 1px solid #c2c2c1 !important; */
  /* box-shadow: rgb(0 0 0 / 5%) 0px 0px 0px 1px, rgb(209 213 219) 0px 0px 0px 0.5px inset; */
}
tr:last-child{
  border-bottom: 0 !important;
}
.table-d-cell {
  color: #3a3a3a;
  font-size: 15px;
}
.table-btn {
  width: 80%;
  padding: 5px;
  border: none;
  border-radius: 0;
  line-height: 15px;
  margin: 5px auto;
  min-width: auto;
  font-size: 15px;
  border-bottom: 2px solid #f29221;
}
.table-d-cell{
  font-size: 15px;
}
.summary-table ul {
  margin: 0px;
  display:grid;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
}
.summary-table tr:not(last-child) td {
  font-size: 17px;
}

.doughnutchart {
  display: block;
  box-sizing: border-box;
  height: 100px;
  width: 70px;
  /* margin-top: -100px; */
}
td {
  width: auto;
}
/* canvas {
  height: 80px !important;
  font-size: 18px;
  margin: 0 auto !important;
  width: 55px !important;
} */
.progress-chart{
  display: flex;
  /* justify-content: center; */
}
/* .summary-table tr:not(last-child) td {
  border-bottom: 1px solid #c2c2c1;
} */

.show_all_course_link > a > button {
  width: auto;
    font-size: 20px;
    padding: 2px 10px;
}
}

@media(max-width:786px){
  .table-btn {
    font-size: 15px;
}
.summary-table tr:not(last-child) td {
  font-size: 15px;
}
}

