.devices-container{
    padding: 5rem 3rem;
}
.devices-container > .notif-top > .notif-title{
    font-size: 23px;
}

@media(max-width:991px){
    .devices-table > thead, .devices-table tr > td {
        font-size: 19px;
    }
}
@media(max-width:786px){
    .devices-table > thead, .devices-table > tr > td {
        font-size: 19px;
    }  
    .devices-container{
        padding:10px;
    }   
}
@media(max-width:500px){
    .devices-table > thead{
        height: 4rem;
    }
}
@media(max-width:450px){
    .devices-container > .notif-top > .notif-title{
        font-size: 20px;
    }
    .devices-table{
        display: block;
        overflow-x: auto;
        margin-bottom: 0;
    }
    .devices-table > thead, .devices-table > tr > td {
        font-size: 15px;
    }
    
}