.m-t-5{
    margin-top: 5px;   
}
/* .m-t-5:hover{
    transition: all 0.3s;
} */
.child-card {
    background: #fff;
    margin-bottom: 30px;
    border: 0;
    border-radius: .1875rem;
    display: inline-block;
    position: relative;
    width: 100% !important;
    box-shadow: none;
    transition: 0.5s;
}
.child-container .child-card .body {
    font-size: 14px;
    color: #424242;
    /* padding: 20px; */
    font-weight: 400;
}
.child-container .profile-header {
    position: relative;
    background-color: #fff;
}

.child-container .profile-header .profile-image img {
    border-radius: 50%;
    width: 140px;
    height: 140px;
    object-fit: cover;
    display: block;
    margin: 0 auto;
    /* border: 3px solid #fff; */
    /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
    background-color: #e7e7e7;    
}

.change-image-icon{
    display: none;
    cursor: pointer;
}
.change-image-icon-container:hover .change-image-icon{
    display: inline-block;
}
.child-container .profile-header .profile-image .change-image-icon{
    position : absolute;
    right: 0;
    top:0;
}

.child-container .profile-sub-header {
    min-height: 60px;
    width: 100%
}

.child-container .profile-sub-header ul.box-list {
    display: inline-table;
    table-layout: fixed;
    width: 100%;
    background: #eee
}

.child-container .profile-sub-header ul.box-list li {
    border-right: 1px solid #e0e0e0;
    display: table-cell;
    list-style: none
}

.child-container .profile-sub-header ul.box-list li:last-child {
    border-right: none
}

.child-container .profile-sub-header ul.box-list li a {
    display: block;
    padding: 15px 0;
    color: #424242
}

.child-btns button {
    /* margin-right: 5px; */
    margin: 0 auto;
    display: block;
}

.p-relative{
    position: relative;
    width: 200px;
    margin: 0 auto;
}
.d-inline-block{
    display: inline-block;
}
/* ** */
.edit-form{
    /* justify-content: flex-end !important; */
}
.add-child {
    /* padding: 10px 60px; */
    width: 200px;
    cursor: pointer;
    margin: 0 auto;
}
.add-child > img {
    cursor: pointer;
    display: block;
    margin: 0 auto;
    width: 140px;
    height: 140px;

}
.add-child > p {
    display: block;
    color: #F39422;
    font-size: 25px;
    text-align: center;
    width: 90%;
    margin: 0 auto;
    margin-top: 60px;
    margin-bottom: 10px;
    line-height: 25px;
}
.each-card {
    display: flex;
    flex-direction: column;
}
.child-info {
    width: 100% !important;
    text-align: center;
}
.edit-child {
    color: #F39422;
    font-size: 20px;
    padding: 0;
    outline: none;
}
.edit-child:hover {
    color: #F39422;
}
.child-info > span > button {
    width: 70%;
    display: block;
    margin: 0 auto;
}
.row-added-children > .col-md-12 > .child-card > .body > .row > .child-info{
    text-align: center;
    margin: 0 auto;
    padding: 0;
    width: 100%;
}
.row-p > .col-md-12 > .child-card > .body > .row > .child-info {
    text-align: left;
    margin: 0 auto;
    padding: 0;
    font-size: 23px;
    display: flex;
    width: 100%;
    padding:0 0 0 30px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: #bdbdbd;
}

.child-img{
    margin: 0 auto;
}
.row-added-children > .col-lg-6 {
    width: 200px;
    /* padding: 10px 60px; */
}
.row-p {
    display: flex;
    width: 100%;
    padding-top: 40px;
    /* padding-bottom: 20px; */
    justify-content: space-around;
}
.row-p > .swiper {
    padding-bottom: 0 !important;
}
.row-p > .col-md-12 {
    width: 500px;
}
 .row-p > .col-md-12 > .child-card > .body > .row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.row-added-children{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 4rem 0;
}
.row-added-children > .col-md-12 > .child-card > .body > .row > .child-img > .profile-image > img{
    margin: 0 auto;
    display: block;
}
.row-p > .col-md-12 > .child-card > .body > .row > .child-img > .m-t-0 {
 color: #F39422;
}
.orange-btn{
    color: #F39422;
    font-size: 25px;
    background-color: transparent;
    border: none;
    padding: 0;
    line-height: 25px;
    text-align: center;
    width: 100%;
    
}

.m-b-0{
    font-size: 25px;
    color: #606060;
    text-align: center;
    text-transform: capitalize;

}
.cards-container{
    /* padding: 1rem 0rem 12rem 0rem; */
    position: relative;
}
.edit-child-form{
    /* padding: 7rem 1rem 7rem 1rem !important; */
}
.child-container > center {
    font-size: 25px;
    padding-bottom: 20px;
}

.child-container > .row-p > .swiper > .swiper-wrapper {
    justify-content: center !important;
}
.swiper-horizontal {
    width: 100%;
}
@media(max-width:992px){
    .child-container > .row-p > .swiper > .swiper-wrapper {
        justify-content: space-between !important;
    }
    .cards-container > .dashboard-contents {
        padding: 0;
    }
    .row-added-children {
        padding: 4rem 0 0;
    }
    .row-p > .col-md-12 > .child-card > .body > .row {
        display: flex;
        flex-direction: column;
    }
    .row-p > .col-md-12 > .child-card > .body > .row > .child-info {
      text-align: center;
      align-items: unset;
      font-size: 19px;
    }
    .child-info{
        text-align: center;
    }
    .child-container .profile-header .profile-image img {
        height: 100px;
        width: 100px;
      }
      .add-child > p {
        margin-top: 45px;
    }
    .add-child > img {
        width: 100px;
        height: 100px;
    }
}
@media(max-width:438px){
    .add-child > p {
        margin-top: 10px;
    }
    .add-child {
        margin-top: 20px;
    }
    .row-added-children {
        padding: 2rem 0;
    }
    .child-info > span > button {
        font-size: 19px;
    }
    .edit-child , .add-child > p {
        font-size: 19px;
    }
}