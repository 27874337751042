.m-b-50 {
    margin-bottom: 50px;
}
  
.dark-link {
    color: #333;
}
  
.heading-line {
    position: relative;
    padding-bottom: 5px;
}
  
.heading-line:after {
    content: "";
    height: 4px;
    width: 75px;
    background-color: #29B6F6;
    position: absolute;
    bottom: 0;
    left: 0;
}
  
.notification-ui_dd-content {
    margin-bottom: 30px;
}
.notif-top {
    align-items: center;
}
.notification-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 20px;
    margin-bottom: 15px;
    border-radius: 10px;
    /* border: 2px solid #e9e9e9; */
    background: #fafafc;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  }
.noti-cont {
    width: 100%;
    display: block;
    max-width: 900px;
    margin: 0 auto;
    margin: 2rem auto !important;
}
.notification-list_detail{
    font-size: 20px;
}
.notification-list--unread {
    /* border-left: 2px solid #29B6F6;
    background: #dfdfe9; */
}
  
.notification-list .notification-list_content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.notification-list .notification-list_content .notification-list_detail p span {
    color: blueviolet;
}
.text-muted > small {
    font-size: 15px;
}
.notification-list .notification-list_content .notification-list_img img {
    height: 55px;
    width: 55px;
    border-radius: 50px;
    border: 1px solid #e9e9e9;
    padding: 2px;
    margin-right: 20px;
}
  
.notification-list .notification-list_content .notification-list_detail p {
    margin-bottom: 5px;
    line-height: 1.2;
}
  
.notification-list .notification-list_feature-img img {
    height: 48px;
    width: 48px;
    border-radius: 5px;
    margin-left: 20px;
}
.rounded-checkbox input[type="checkbox"]:checked + label:before, .rounded-checkbox input[type="checkbox"]:checked + label:after {
    background-color: #f49422;
    border: 1px solid #f49422;
    opacity: 1;
    position: absolute;
    top: unset;
}
@media(max-width:992px){
  .notif-title {
      margin-left: 10px;
      font-size: 23px;
      margin-bottom: 0px;
  }
  .checkboxes-container {
      margin: 10px 0px;
      font-size: unset;
      line-height: 1.1;
      margin-left: 1.5em;
  }
  .rounded-checkbox > div > label {
      font-size: 19px;
      padding-left: 0;
      padding-bottom: 0;
      /* display: inline-block; */
  }
  .notif-top > img {
      width: 40px;
  }
  .notification-list {
      flex-direction: column;
  }
}