.mobile-history-payment{
    display: none;
  }
  
  
  
  
  @media(max-width:600px){
  
    .mobile-history-payment{
      display: block;
    }
    .wide-screen-history-payment{
      display: none !important;
    }
  
  }