.gray-bg {
    position: relative;
    background-color: #ececec;
    border-radius: 250px 0 250px 0;
    margin-top: 13rem;
    padding-top: 60px;
    padding-bottom: 60px;
    /* height: 550px; */
}

.nav-tabs {
    display: flex;
    justify-content: space-evenly;
    width: 40%;
    border-bottom: 0;
    margin: 0 auto;
}
.nav-link {
    width: 200px;
    font-size: 25px;
    background-color: #fff;
    padding: 14px 10px;
    color: #6d6b6c;
    border: 1px solid rgb(202, 202, 202);
    border-radius: 50px;
}
.nav-link.active{
    background-color:#6fbba4;
    color: #fff;
    border: none;
}
.nav-link.active:hover{
    background-color:#579683;
    transition: all 0.3s;
    /* transition: background-color .2s ease-in, color .2s ease-in; */
}
.all-courses-container{
    display: flex;
    width: 85%;
    margin: 0 auto;
    height: 100%;
    padding: 50px 0 !important;
    justify-content: center;
    position: relative;
}
.dashboard-courses-container{
    /* display: flex; */
    width: 85%;
    margin: 0 auto;
    height: 100%;
    padding: 50px 0 !important;
    /* justify-content: center; */
    position: relative;
}
.course-icon {
    width: 100%;
    max-width: 135px;
    display: block;
    margin: 0 auto;
}
.menu-link{
    /* color: #6d6b6c !important; */
    font-size: 25px;
}
.course-btn {
    border: none;
    border-radius: 25px;
    margin: 0 auto;
    font-size: 25px;
    margin-top: 20px;
    padding: 4px 0;
    display: flex;
    width: 150px;
    color: #fff !important;
    background-color: #F39422;
    justify-content: center;
}
.course-btn:hover{
    color: #fff !important;
    background-color: #e0881b;
}
.course-btn > a{
    text-decoration: none;
    color: #fff;
}
a{
    text-decoration: none !important;
}
/* .owl-nav button.owl-next {
    background: none;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
    
} */
/* .owl-prev{
    display: none !important;
} */
.tips-title{
    color: #676767;
    text-align: center;
    font-weight: normal;
    font-size: 25px;
    /* padding: 0px 20px 30px 20px; */
    margin-bottom: 50px;
}
.videos-carousel-container > .slider-items > .owl-nav > .owl-next {
    border-radius: 0;
    background-image: linear-gradient(to right, rgba(255,0,0,0), #fd8b02) !important;
    background-color: transparent !important;
    width: 15% !important;
    height: 100% !important;
    opacity: 45% !important;
}
.videos-carousel-container{
    width: 100% !important;
    margin: 0 auto;
}
/* the below is for display the next or prev when cards loop is end  */
.slider-items > .owl-nav .disabled { 
	display: none; 
}
.all-courses-container > .slider-items > .owl-nav > .owl-next,
.all-courses-container .swiper-button-next 
{
    border-radius: 0% 100% 100% 0% / 0% 50% 50% 0% !important;
    background-image: linear-gradient(to right, rgba(255,0,0,0), #fd8b02) !important;
    background-color: transparent !important;
    /* left: 92.5% !important; */
    width: 160px !important;
    /* height: 130% !important; */
    /* top: -13% !important; */
    opacity: 45% !important;
    transition:  .2s ease-in;

}

.all-courses-container > .slider-items > .owl-nav > .owl-prev,
.all-courses-container .swiper-button-prev
{
    border-radius: 100% 0% 0% 100% / 50% 0% 0% 50% !important;
    background-image: linear-gradient(to left, rgba(255,0,0,0), #fd8b02) !important;
    background-color: transparent !important;
    right: -90% !important;
    width: 160px !important;
    left: -10%;
    height: 100% ;
    /* top: -13% !important; */
    opacity: 45% !important;
    transition:  .2s ease-in;
}
.clickable{
    cursor: pointer;
}
.previous-swipe{
    height: 100% !important;
    /* top: 3%  !important; */
    left: -50px !important;
    position: absolute;
}
.next-swipe{
    height: 100% !important;
    /* top: 3%  !important; */
    right: -50px !important;
    position: absolute;
}
.prevSwipeCard
{
   display: none;
}
.nextSwipeCard
{
  display: none;
}
.swiper-slide div .menu-link {
    text-align: center !important;
    height: 85px;
    line-height: 28px !important;
}
/* Sub cat courses */
.courses-form-subcat > div{
    padding: 10px;
    width: 33%;
}
.courses-from-cat > div {
    padding: 0px 0px 7rem 0px;
    width: 100%;
}
.courses-form-subcat , .courses-from-cat {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.courses-form-subcat > div > .each-course {
    padding: 10px 0 20px 0;
}

.back-blue-btn{
    display: flex;
    align-items: center;
    width: 90px;
    justify-content: space-between;
    padding: 0 0 25px 0;
}
.back-blue-btn > p{
    margin-bottom:  0;
    color :#a5d5c7;
}
.single-cat-name{
    margin: 0;
    text-align: center;
    color: #000;
    padding: 0 15px 20px 15px;
    font-size: 22px;
}
.courses-from-cat > .single-course > .each-course > p {
    padding: 10px 0 0 0;
    margin: 0;
}
.courses-statement{
    text-align: center;
    color: #6A6A6A;
    width: 100%;
    font-size: 25px;
    max-width: 700px;
    padding: 25px 0 25px 0;
    /* margin: 3rem 0 3rem 0; */
}
.cat-item {
    display: flex;
    align-items: center;
    color: #4D2B85;
    font-size: 16px;
    font-weight: 700;
    justify-content: flex-start;
    width: 90%;
    margin: 0 auto;
}
.cat-item > img {
    width: 100%;
    max-width: 70px;
}
.each-course .course-poster-icon{
    width: 100% !important;
    max-width: 260px;
    height: 100% !important;
}
.each-course > .each-course-name{
    padding: 0 !important;
    margin: 0 !important;
    color: #8f8f8f ;
}
.swiper-course1 > .cat-item > .single-cat-name{
    color: #4D2B85;
}
.swiper-course1{
    padding-bottom: 1.7rem !important;
}
.swiper-course1 > .swiper > .swiper-button-next:after, .swiper-course1 > .swiper > .swiper-button-prev:after{
    display: block !important;
    color: #fff;
    font-weight: bolder;
    font-size: 30px;
}
.swiper-course1 > .swiper > .swiper-button-next, .swiper-course1 > .swiper > .swiper-button-prev{
    opacity: 100% !important;
    height: 80% !important;
    display: none;
}
.swiper-course1 > .swiper > .swiper-button-next:after{
    margin-left: 50%;
}
.swiper-course1 > .swiper > .swiper-button-prev:after{
    margin-right: 50%;
}
/* Uploaded project */
.white-box > #enroll-message > .modal-dialog  > .modal-content2 > .modal-body > h4 > a {
    width: 90%;
    margin: 0 auto;
    display: block;
    text-align: center;
    padding: 10px 0;
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: ltr;
}

@media(max-width:1100px){
    .nav-tabs {
        display: flex;
        justify-content: space-evenly;
        width: 70%;
        border-bottom: 0;
        margin: 0 auto;
    }
}
@media(max-width:992px){
    .nav-tabs {
        padding-top: 40px;
    }
    .all-courses-container .swiper .swiper-wrapper .swiper-slide {
        flex-shrink: 0;
        width: 30% !important;
        /* margin-right: 20px !important; */
        height: 100%;
    }
   .swiper-slide div .menu-link {
        font-size: 19px;
        text-align: center !important;
        justify-content: center !important;
        height:70px;
        font-size: 18px !important;   
        line-height: 20px !important;     
    }
.swiper-course1{
    padding-bottom: 1rem !important;
}
.swiper-course1 > .cat-item {
    margin-bottom: 1rem;
}
    .course-btn {
        font-size: 17px !important;
        width: 100%;
    }
    .all-courses-container > .slider-items > .owl-nav > .owl-next, .all-courses-container .swiper-button-next {
        left: 85% !important;
        width: 20% !important;
        height: 70% !important;
        top: 5% !important;
    }
    .all-courses-container > .slider-items > .owl-nav > .owl-next, .all-courses-container .swiper-button-prev {
        left: -5% !important;
        width: 20% !important;
        height: 70% !important;
        top: 5% !important;
    }

    .course-btn {
        margin-top: 0px;
    }
    .prevSwipeCard
    {
        border-radius: 100% 0% 0% 100% / 50% 0% 0% 50% !important;
        /* background-image: linear-gradient(to left, rgba(255,0,0,0), #fd8b02) !important; */
        background-image: none !important;
        background-color: transparent !important;
        right: -90% !important;
        width: 90px !important;
        left: -10%;
        top: 0 !important;
        height: 70% !important;
        /* top: -13% !important; */
        /* opacity: 45% !important; */
        opacity: 1 !important;
        transition:  .2s ease-in;
        display: block;
    }
    .nextSwipeCard
    {
        border-radius: 0% 100% 100% 0% / 0% 50% 50% 0% !important;
        /* background-image: linear-gradient(to right, rgba(255,0,0,0), #fd8b02) !important; */
        background-color: transparent !important;
        background-image: none !important;
        /* left: 92.5% !important; */
        width: 90px !important;
        height: 70% !important;
        top: 0 !important;
        /* opacity: 45% !important; */
        opacity: 1 !important;
        transition:  .2s ease-in;
        display: block;
    }
    .courses-from-cat >  div {
        padding: 0px 0px 4rem 0px;
    }
}
@media(max-width:786px){
    .single-cat-name{
        font-size: 19px;
        /* padding-top: 30px; */
    }
    .swiper-course1 > .swiper > .swiper-button-next:after, .swiper-course1 > .swiper > .swiper-button-prev:after {
        font-size: 12px;
    }
}
@media(max-width:700px){
    .nav-tabs {
        width: 90%;
    }
}
@media(max-width:650px){
    .all-courses-container > .slider-items > .owl-nav > .owl-next,
.all-courses-container .swiper-button-next:after{
    display: block !important;
    color: #F39422;
    font-weight: bolder;
    font-size: 30px;
    left: 50%;
    top: 15%;
    position: relative;

}
.all-courses-container > .slider-items > .owl-nav > .owl-next,
.all-courses-container .swiper-button-prev:after{
    display: block !important;
    color: #F39422;
    font-weight: bolder;
    font-size: 30px;
    left: -50%;
    top: 20%;
    position: relative;

}
.swiper-slide div .menu-link {
    font-size: 15px !important;
}
.all-courses-container .swiper-button-next 
{
    background-image: none !important;
    opacity: 1 !important;
}

.all-courses-container > .slider-items > .owl-nav > .owl-prev,
.all-courses-container .swiper-button-prev
{
    background-image: none !important;
    opacity: 1 !important;
}
}
@media(max-width:550px){
    .nav-link {
        width: 150px;
        font-size: 19px;
        padding: 7px 10px;
    }
    .all-courses-container > .slider-items > .owl-nav > .owl-next, .all-courses-container .swiper-button-next {
        left: 85% !important;
        width: 20% !important;
        height: 60% !important;
        top: 5% !important;
    }
    .all-courses-container > .slider-items > .owl-nav > .owl-next, .all-courses-container .swiper-button-prev {
        left: -5% !important;
        width: 20% !important;
        height: 60% !important;
        top: 5% !important;
    }
    .courses-form-subcat > div {
        display: inline-block;
        width: 50%;
    }
    .courses-from-cat > div {
        display: inline-block;
        width: 100%;
    }
    .cat-item > img {
        max-width: 50px;
    }
    .courses-statement{
        font-size: 15px;
        line-height: 25px;
        padding: 30px 10px 0 10px;
    }
    .each-course > .each-course-name {
        font-size: 16px;
        line-height: 30px;
    }
    .cat-item {
        margin-bottom: 10px;
    }
}
@media(max-width:430px){
    .each-course .course-poster-icon {
        width: 130px !important;
        height: 130px !important;
        object-fit: cover;
        border-radius: 15px;
    }
    .swiper-course1 > .swiper > .swiper-button-next, .swiper-course1 > .swiper > .swiper-button-prev {
        height: 78% !important;
    }
}
/* @media(max-width:400px){
    .swiper-course1 > .swiper > .swiper-button-next, .swiper-course1 > .swiper > .swiper-button-prev {
        height: 70% !important;
    }
} */
@media(max-width:390px){
    .nav-tabs {
        width: 100%;
    }
    .nav-link {
        width: 140px;
    }
}

.show_all_course_link{
    position: absolute;
    bottom:10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* right:15%; */
}