/* Privacy Policy, Payment policy, and Terms Pages styles */
body{
    /* background-color: #ececec; */
}
/* terms and policy */
.section-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding:7rem 1rem 7rem 1rem;
    margin: 0;    
    background-color: #e9e9e9;
    /* height: calc(100vh - 195px); */
}
.content-container{
    width: 95%;
    margin: 0 auto;
    background-color: #fff;
    color: #606060;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    border-radius: 55px;
    padding: 5rem 10rem;
    font-size: 25px;
}
.notif-title{
    margin-left: 10px;
    font-size: 28px;
    margin-bottom: 5px;
}
.notif-top{
    display: flex;
    align-items: flex-end;
}
.rounded-checkbox > div > label {
    font-weight: normal;
    font-size: 30px;
    display: flex;
    align-items: center;
    color: #adadad;
}

@media only screen and (max-width: 992px) {
    .section-container{
        padding:2rem 1rem;
    }
    .content-container {
        padding: 1rem;
        border-radius: 25px;
        font-size: 22px;
    }
}