.survey-container{
    height: 100%;
    background-color: #808080;
}

.survey-header{
    padding:0 20px;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    background-color: #fff;
}
.mawaheb-logo {
    width: 130px;
    padding: 15px;
}
.survey-header-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    background-color: #fff;
}
.survey-header-item > a{
    margin: 0 auto;
}
.survey-header-item {
    margin: 0 auto;
    font-size: 22px;
    display: flex;
    color: #6dbba5;
    align-items: center;
    width: 33.3%;
    justify-content: center;
}
.survey-header-item:nth-child(1){
    justify-content: flex-start;
}
.survey-header-item:nth-child(3){
    justify-content: flex-end;
}
.m-logo{
    justify-content: center !important;
}
.survey-header-item > img{
    width: 40px;
    padding: 0 5px;
}
.survey-progress-contriner{
    /* margin: 20px 0 0 0px; */
    background-color: rgb(228, 226, 226);
    height: 3px;
    border-radius: 20px;
    direction: rtl;
    width: 95%;
    max-width: 700px;
    margin: 0 auto;
}
.answers-container > .submit-button-auto{
    padding: 8px 30px 8px 30px;
}
.survey-progress-bar{
    background-color: #6dbba5;
    height: 100%;
    width:0px;
    transition: width 0.5s;
}

.survey-question-body {
    width: 100%;
    max-width: 700px;
    text-align: center;
    margin: 0 auto;
    height: 100%;
    background-color: #fff;
}
.survey-question-body > h1{
    font-size: 22px;
    color: #501d93;
    margin: 0;
    font-weight: bold;
    padding: 20px 0;
}
.survey-question-body > h4{
    color: #6dbba5;
    font-size: 20px;
}
.intro-image{
    margin: 30px auto;
    width: 90%;
    max-width: 300px;
}
.question-title {
    text-align: center;
    color: #501d93;
    padding: 22px 0 14px 0;
    margin: 0;
    width: 70%;
    max-width: 500px;
    margin: 0 auto;
    font-weight: bold;
}
.question-title2{
    font-size: 16px !important;
    padding: 8px 0 0 0;
}
.answers-container {
    display: flex;
    /* padding: 30px; */
    justify-content: space-evenly;
    align-items: stretch;
    width: 700px;
    margin: 0 auto;
    flex-direction: column;
    padding: 0 30px;
}
.answer-item {
    padding: 10px 50px 10px 0;
    background-color: #f5f5f5;
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    /* max-width: 15%; */
    height: 70px;
    width: 100%;
    margin: 8px 0;
    align-items: center;
}
.answer-item.selected{
    background-color: #c8c8c8;
}
.anwser-img {
    display: block;
    margin: 0 auto;
    width: 65px;
}
.anwser-text {
    font-size: 18px;
    text-align: right;
    font-weight: bold;
    padding: 10px 0;
    width: 50%;
    direction: rtl;
}
.survey-header-item > div{
    cursor: pointer;
    /* margin-top: -10px; */
}
.survey-form-img{
    margin: 0 auto;
    display: block;
    width: 100%;
    max-width: 150px;
    padding-top: 50px;

}
.survey-field {
    padding: 7px 13px !important;
    width: 100% !important;
    max-width: 450px !important ;
    margin: 0 auto !important;
}
.survey-field > input {
    padding: 10px 20px !important;
    direction: ltr !important;
}
.survey-email::placeholder{
    text-align: right !important;
    padding: 0 10px;
}
@media(max-width:768px){
    .survey-question-body > button {
        margin: 3.5rem auto 0rem;
        padding: 8px 30px 8px 30px;
    }
}
@media(max-width:700px){
    .mawaheb-logo {
        width: 130px;
    }
    .survey-question-body{
        width: 100%;
    }
    .survey-question-body > h1 {
        font-size: 18px;
        color: #501d93;
        font-weight: bold;
        width: 90%;
        max-width: 300px;
        margin: 0 auto;
        line-height: 28px;
        padding-top: 35px;
    }

    .intro-image {
        width: 90%;
        max-width: 180px;
    }
    .survey-question-body > h4 {
        padding: 0;
    }
    .survey-question-body > button{
        margin: 3.5rem auto 0rem;
        padding: 8px 30px 8px 30px;
    }
    .answers-container {
        width: 90%;
        flex-direction: column;
        padding: 10px 0;
    }
    .answer-item {
        width: 100%;
        max-width: 100%;
        height: 60px;
        margin: 4px 0;
        padding: 10px;
    }
    .question-title {
        font-size: 20px;
    }
    .anwser-text {
        font-size: 13px !important;
        width: 70%;
    }
    .survey-email::placeholder{
       font-size: 16px;
    }
    .survey-field > input {
        padding :10px 20px !important;
    }
    .intro-image {
        margin: 55px auto;
    }
    .survey-header{
        padding: 0;
    }
    .survey-header-item > img {
        width: 30px;
    }
    .survey-header-item {
        font-size: 15px;
    }
    .intro-int > form > .submit-survey{
        margin: 2rem auto 0rem;
        padding: 8px 30px 8px 30px;
    }
}