/* Testimonials CSS */
.testimonials{
  background-color: #e0efea;
  padding: 2rem 0 8rem 0;
}
.testimonials-title{
  color: #999999;
  font-size: 25px;
  text-align: center;
  margin: 40px 0;
}
.testimonials > .swiper > .swiper-wrapper > .swiper-slide-next, .testimonials > .swiper > .swiper-wrapper > .swiper-slide-prev,  .testimonials > .swiper > .swiper-wrapper > .swiper-slide-active {
  /* width: 50% !important; */
  background-color: #fff;
}
/* .testimonials > .swiper > .swiper-wrapper > .swiper-slide-active{
  margin-left: -30%;
} */
.testimonials > .swiper > .swiper-wrapper > .swiper-slide-next{
  /* width: 55% !important; */
}
.testimonials > .swiper > .swiper-wrapper {
  padding: 40px 0px;
  background-color: #fff;
}
.testimonials > .swiper{
  width: 100% !important;
  background-color: #fff !important;
  padding-bottom: 0 !important;
}
.testimonial-card{
  display: flex;
  justify-content: center;
  padding: 25px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 20px;
  background-color: #F6F6F6;
  height: 100%;
}
.customer-qoute{
  /* padding: 0 0 0 15px; */
  width: 100%;
  margin: 0 auto;
}
.customer-img {
  width: 30%;
/* display: flex; */
  display: none;
  align-items: flex-start;
}
.customer-img > img {
  /* max-width: 160px; */
  width: 100%;
  display: flex;
  margin: 0 auto;
  align-items: center;
}
.qoute-text{
  font-size: 14px;
  text-align: justify !important;
  direction: ltr;
  color: #6A6A6A;
}
.qoute1 > div > img{
  width: 30px;
  height: 25px;
  margin-bottom: 20px;
}
.qoute2 > div > img{
  width: 30px;
  float: right;
  transform: rotate(180deg);
}
.testimonials > .swiper > .swiper-button-next{
  width: 25% !important;
  background-image: linear-gradient(to left, #7cc1ac, #8ccdcb, #a8d8e2, #c7e2f0, #e4edf8, #e4edf8, #e4edf8, #e4edf8, #c7e2f0, #a8d8e2, #8ccdcb, #7cc1ac) !important;
  height: 100% !important;
  opacity: 65% !important;
  background-color: transparent !important;
  border-radius: 0;
  top: 0 !important;
}
.testimonials > .swiper > .swiper-button-prev{
  width: 25% !important;
  background-image: linear-gradient(to left, #7cc1ac, #8ccdcb, #a8d8e2, #c7e2f0, #e4edf8, #e4edf8, #e4edf8, #e4edf8, #c7e2f0, #a8d8e2, #8ccdcb, #7cc1ac) !important;        
  height: 100% !important;
  opacity: 65% !important;
  background-color: transparent !important;
  border-radius: 0;
  top: 0 !important;
}
/* ****************************** */
.testimonials-wrapper{
  width: 100%;
  height: 100%;
  background-color: #fff;
  margin-top: 0 !important;
}
.bk-slider {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0px;
}
.bk-slider > .swiper{
  padding-bottom: 0px !important;
}
/* .swiper {
  width: 100%;
  height: 100%;
  overflow: visible;
} */


.bk-slider > .swiper > .testimonial-swiper {
  width: 95% !important;
  margin: 0 auto;
}
.bk-slider > .swiper > .swiper-wrapper > .slider-items > .swiper-wrapper > .swiper-slide {
  /* text-align: center; */
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  background: transparent;
  transition: all .3s ease;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
  padding-bottom: 100px !important;
  
  &.swiper-slide-active {
    /* transform: scale(1.7); */
    z-index: 2;
  }

  &.swiper-slide-active > .testimonial-card > .customer-qoute > .qoute-text {
      font-size: 11px;
      font-weight: bold;
    }
  &.swiper-slide-prev {
      transform: scale(0.9);
      opacity: 0.5;
    }

    &.swiper-slide-next {
      transform: scale(0.9);
      opacity: 0.5;
    }
}
/* .swiper-slide-active > div > .testimonial-card > .customer-qoute > .qoute-text {
  font-size:12px;
} */
.bk-slider > .swiper > .swiper-wrapper > .slider-items{
  width: 90% !important;
}
.bk-slider > .swiper > .swiper-wrapper > .slider-items > .swiper-button-next , .bk-slider > .swiper > .swiper-wrapper > .slider-items > .swiper-button-prev{
  background-color: transparent !important;
  background-image: none !important;
  opacity: 1 !important;
  top: 50%;
  width: calc(var(--swiper-navigation-size)/ 44 * 27);
}
.bk-slider > .swiper > .swiper-wrapper > .slider-items > .swiper-button-next:after, .bk-slider > .swiper > .swiper-wrapper > .slider-items > .swiper-button-prev:after {
  display: block !important;
  color: #6ebca6;
  font-weight: bolder;
  font-size: 30px;
  top: 0 !important;
}
/* .bk-slider > .swiper > .swiper-wrapper > .slider-items > .swiper-button-prev{
  left: -5% !important;
  top: 0px !important;
}
.bk-slider > .swiper > .swiper-wrapper > .slider-items > .swiper-button-next{
  top: 0px !important;
  right: -5% !important;
} */
.bk-slider > .swiper > .swiper-wrapper > .slider-items  {
  position: unset !important;
}


/* dots */
.swiper-pagination
.swiper-pagination-bullet {
  width: 7px;
  border-radius: 25px;
  height: 7px;
  background-color: #ACEAC7;
  margin: 0;
  margin-right: 25px;
  cursor: pointer;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16);
  display: inline-block;
  position: relative;
  z-index: 5000;
  transition: opacity 0.5s, background-color 0.5s, width 0.5s;
  transition-delay: 0.5s, 0.5s, 0s;
}
/* .swiper-pagination{
      text-align: center;
      margin-top: -20px;
      display: block;
      z-index: 100;
      visibility: visible;
      opacity: 1;
      margin-bottom: 5%;
} */
.swiper-pagination {
text-align: center;
display: block;
position: absolute;
z-index: 100;
visibility: visible;
opacity: 1;
bottom: 10%;
left: 50%;
width: 100%;
transform: translate(-50%, -50%);
}
.swiper-pagination-bullet-active{
  width: 30px !important;
  transition: opacity 0.5s, background-color 0.5s, width 0.5s;
  transition-delay: 0.5s, 0.5s, 0s;
  background-color: #6FBBA4 !important;
}
.slider_controls {
  position: relative;
  z-index: 12;

  & .slider__pagination {
    text-align: center;
    margin-top: 50px;

    & .swiper-pagination-bullet {
      width: 13px;
      height: 10px;
      display: inline-block;
      background: #fff;
      opacity: 0.2;
      margin: 0 5px;
      -webkit-border-radius: 20px;
      border-radius: 20px;
      -webkit-transition: opacity 0.5s, background-color 0.5s, width 0.5s;
      -o-transition: opacity 0.5s, background-color 0.5s, width 0.5s;
      transition: opacity 0.5s, background-color 0.5s, width 0.5s;
      -webkit-transition-delay: 0.5s, 0.5s, 0s;
      -o-transition-delay: 0.5s, 0.5s, 0s;
      transition-delay: 0.5s, 0.5s, 0s;
    }
  }

  & .swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer;
  }

  & .slider_pagination .swiper-pagination-bullet-active {
    opacity: 1;
    background: #ffffff;
    width: 100px;
    -webkit-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
  }

  & .slider_button-next,
  .slider_button-prev {
    position: absolute;
    top: 50%;
    width: 27px;
    height: 44px;
    margin-top: -22px;
    z-index: 10;
    cursor: pointer;
    -webkit-background-size: 27px 44px;
    background-size: 27px 44px;
    background-position: center;
    background-repeat: no-repeat;
  }

  & .slider_button-prev {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
    left: 10px;
    right: auto;
  }

  & .slider_button-next {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
    right: 10px;
    left: auto;
  }

  & .slider_button-prev:focus,
  .slider_button-next:focus {
    outline: none !important;
  }
}
.bk-slider>.swiper>.swiper-wrapper.testimonial-swiper>.slider-items>.swiper-button-next, .bk-slider>.swiper>.swiper-wrapper.testimonial-swiper>.slider-items>.swiper-button-prev {
    background-color: initial !important;
    background-image: none !important;
    opacity: 1 !important;
    top: 50% !important;
    width: calc(var(--swiper-navigation-size) / 44* 27) !important;
}
@media(max-width:768px){

  .testimonials > .swiper > .swiper-wrapper {
      padding: 10px 0px;
      background-color: #fff;
  }
  .testimonial-card {
      flex-direction: column;
      padding: 7px;
  }
  .testimonials-title{
      line-height: unset;
      /* margin-bottom: 25px;
      padding-top: 25px; */
      font-size: 20px;
  }
  .customer-img > img {
      max-width: 130px;
      margin: 0 auto;
      width: 100%;
  }

  .customer-qoute {
      padding: 0px;
      width: 95%;
      margin: 0 auto;
  }
  .customer-img {
      width: 100%;
      display: none;
  }
  .bk-slider{
      /* padding-bottom: 20px; */
  }
  .swiper-slide-active > .testimonial-card > .customer-qoute > .qoute-text {
      font-size: 6px !important;
  }
  .bk-slider > .swiper > .swiper-wrapper > .slider-items {
      width: 100% !important;
      margin-left: 0 !important;
  }
  .qoute-text {
      font-size: 16px;
  }
  .swiper-slide-active > div > .testimonial-card > .customer-qoute > .qoute-text{
      /* font-size: 6px !important; */
  }
  .bk-slider > .swiper > .swiper-wrapper > .slider-items  {
      position: unset !important;
  }
  .bk-slider > .swiper > .swiper-wrapper > .slider-items > .swiper-wrapper > .swiper-slide-next , .bk-slider > .swiper > .swiper-wrapper > .slider-items > .swiper-wrapper > .swiper-slide-prev {
      transform: scale(1) !important;
  }
  .qoute1 > div > img {
      width: 15px;
      margin-bottom: 5px;
  }
  .bk-slider > .swiper > .swiper-wrapper > .slider-items > .swiper-button-prev{
      left: 0 !important;
      display: none;
  }
  .bk-slider > .swiper > .swiper-wrapper > .slider-items > .swiper-button-next{
      right: 0 !important;
      display: none;
  }
  .swiper-pagination .swiper-pagination-bullet {
      margin-right: 15px;
  }
  .swiper-pagination {
    margin-top: -10px;
}
}