
@font-face {
  font-family: 'STC';
  src: url('/public/assets/css/STC_Font/STC-Regular.eot');
  src: url('/public/assets/css/STC_Font/STC-Regular.eot?#iefix') format('embedded-opentype'),
      url('/public/assets/css/STC_Font/STC-Regular.woff2') format('woff2'),
      url('/public/assets/css/STC_Font/STC-Regular.woff') format('woff'),
      url('/public/assets/css/STC_Font/STC-Regular.ttf') format('truetype'),
      url('/public/assets/css/STC_Font/STC-Regular.svg#STC-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family:"STC" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* PreLoader */
.loader-bg {
  position:fixed;
  z-index:999999;
  background:#fff;
  width:100%;height:100%
}
.loader{
  border:0 solid transparent;
  border-radius:50%;
  width:150px;
  height:150px;
  position:absolute;
  top:calc(50vh - 75px);
  left:calc(50vw - 75px)
}
.loader:after,.loader:before{
  content:'';
  border:1em solid #f49422;
  border-radius:50%;
  width:inherit;
  height:inherit;
  position:absolute;
  top:0;
  left:0;
  animation:loader 2s linear infinite;
  opacity:0
}
.loader:before{
  animation-delay:.5s}@keyframes loader{0%{transform:scale(0);opacity:0}50%{opacity:1}100%{transform:scale(1);opacity:0}}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}
.active:hover{
  color: #000;
}
.submit-button-auto {
  background-color: #F39422;
  /* opacity: 90%; */
  color: #fff;
  border: none;
  outline: none;
  width: 230px !important;
  /* margin-top: 2rem; */
  display: block;
  cursor: pointer;
  margin:4rem auto 4rem;
  padding: 4px 30px 4px 30px;
  font-size: 25px;
  border-radius: 30px;
}
.reg-btn{
  width:230px;
}
.submit-button-auto:hover{
  background-color:#ff8800;
}

button:disabled{
  opacity: 60%;
}

.form-group.error-input input, .form-group.error-input label, p.error-message{
  border-color: #e73535 !important;
  color: #e73535 !important;
}
.form-group p.error-message{
  margin-top:10px !important;
}