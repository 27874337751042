/* .reg-opacity{
    width:100%; 
    margin-top: 110px;
    height:100%; 
    z-index:1;     
    background-color: #E2F4EE;
    background-image: url(../images/bg2.png);
    background-size: cover;
} */
/* OTP page */
*,
*::before,
*::after {
  box-sizing: border-box;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.otp-form-wrapper {
  max-width: 500px;
  margin: 0 auto;
  padding-top: 25px;
}
.otp-input {
  width: 40px;
  height: 45px;
  border-radius: 0px;
  background: #f2f2f2;
  border: 1px solid #f2f2f2;
  /* padding: 12px 19px; */
  font-size: 16px;
  line-height: 1.2;
  font-weight: 400;
  color: #212121;
  transition: all 0.3s ease-in-out;
  outline: none !important;
  text-align: center;
}
.otp-input:focus {
  border-color: #000;
}
.code-inputs {
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  width: 100%;
  max-width: 280px;
}
.otp-btns{
  display: flex;
  flex-direction: column;
}
.otp-btns > .submit-button-auto{
   margin: 4rem auto 2rem;
}
.otp-btns > .secondary-btn {
  border: none !important;
  margin: 0rem 0 1rem 0 !important;
}

._1-sentence{
  color: #579683;
  font-size:30px;
  text-align: left;
  margin-bottom: 20px;
  
}
._2-sentence, ._3-sentence{
  text-align: left;
  font-size: 17px;
}
._4-sentence {
  text-align: left;
  font-size: 33px;
}
.otp-text{
  text-align: left;
  font-size: 17px;
}
.otp-text > a {
  color: #F39422 !important;
}
.otp-form{
    display: flex;
    gap: 50px;
    justify-content: space-between;
    align-items: flex-start;
}
/* Register */
.reg-container{
  width: 95%;
  max-width: 700px !important;
}
.reg-form{
    position:relative;
    width:85%; 
    max-width: 550px;
    margin: 0 auto;
    height:100%; 
}
.reg-form > form {
    width:90%;
    margin: 20px auto 40px auto;
}
.reg-title{
    color: #777c7d;
    text-align: center;
    padding-top:40px;
    margin: 0px ;
    font-size: 25px;
    font-weight: normal;
  }
  .reg-input,.reg-input-2 {
    width: 100%;
    border-radius: 30px !important;
    border: 1px #bebcbc solid ;
    font-size: 19px !important;
    /* padding:9px 42px !important; */
    font-family: "STC";
    background-color: #ffffff;
    outline: none;
    color: #908f91;
}

#email {
  text-align: left;
  direction: ltr;
}
.behalf{
  padding: 10px 30px !important;
}
.custom-input{
  padding: 10px 43px !important;
}
.reg-input > select {
  width: 115px !important;
  margin-left: -30px !important;
}
.inline-group > .col-md-6:nth-child(1){
   padding-left: 0px !important;
}
.inline-group > .col-md-6:nth-child(2){
   padding-right: 0px ;
}
.reg-input::placeholder, .reg-input-2::placeholder{
    color: #c9c9c8 !important;
    font-size: 23px;
    font-family: "STC";
  }
  .mob-input::placeholder{
    margin-right: 20px !important;
  }
  .form-select {
    position: absolute;
    border-radius: 25px 0 0 25px;
    left: 0;
    border: none;
    z-index: 5;
    margin-bottom: 0;
    color: #908f91;
    margin-top: 0;
    outline: none;
    width: 90px;
    padding-left: 0;
    font-size: 19px;
    border-right: solid 1px #bebcbc;
    -webkit-appearance: none;
    background-image: url(http://localhost:3000/static/media/arrow-down.463298221766383a40a9.svg);
    background-repeat: no-repeat;
    background-position: center right 10px;
    background-size: 12px;
    background-color: transparent;
    height: 49px;
    /* margin-left: 5px; */
    text-align: center;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: #908f91 !important; 
  -webkit-box-shadow: inset 0 0 0px 9999px rgb(255, 255, 255) !important;
  font-weight: normal;
  font-size: 18px;

}
.inline-group{
    display:inline-flex;
    justify-content: space-between;
    width: 100%;
}
.inline-group > input {
    width: 48%;
}
.dob_options_container > .col-md-3 {
  padding-left: 0;
}
.field-icon {
    position: absolute;
    z-index: 1;
    right: 4%;
    top: 44%;
    width: 27px;  
    color: #c9c9c8;
    font-size: 20px !important;
} 
.input-checkbox[type=checkbox].ckb {
    z-index: -1;
    opacity: 0;
    display: none;
  }
  .input-checkbox[type=checkbox].ckb + label {
    display: inline-flex;
    align-items: center;
    font-size: 19px;
    font-weight: normal;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    transition: all 0.3s;
    color: #b6b6b3;
    width: 100%;
    height: 30px;
    justify-content: flex-start;
    padding: 0;
}
  .input-checkbox[type=checkbox].ckb + label::before {
    transition: all 0.3s;
    content: "";
    display: inline-block;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 2px solid #c9c9c8 !important;
    border-radius: 100%;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    margin: 20px 5px;
  }
  .input-checkbox[type=checkbox].ckb:checked + label::before {
    transition: all 0.3s;
    border-color: #6fbba4 !important;
    background-color: #6fbba4 !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e") !important;
    box-shadow: 2px 2px 10px 0px #6fbba4 !important;
  }
  .select-role {
    font-size: 19px;
    width: 100%;
    margin: 0 auto !important;
}
.date-label {
  width: 100% !important;
}
 .flex-group{
    display: flex ;
    margin-top: 30px !important;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between !important;
 }
 .flex-inner-group{
  display: flex ;
  margin-top: 30px !important;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between !important;
 }
 .checking > .error {
    width: 50% !important;
    text-align: center;
    height: 30px;
}
/* .checking{
   margin: 0 !important;
   height: 30px;
} */
.login-input{
    height: 51px;
    width: 100%;
    border-radius: 50px !important;
    border: 1px #bebcbc solid !important;
    font-size: 18px !important;
    padding: 12px 45px !important;
    font-family: "STC";
    background-color: #ffffff !important;
    outline: none;
    color: #908f91;
}
.reg-input > select{
    width: 100%;
    font-size: 19px;
    border: none;
    outline: none;
    background-color: #fff;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url(../../../public/assets/images/arrow-down.svg);
    background-repeat: no-repeat, repeat;
    background-position: left 100% top 50%, 0 0;
    background-size: 12px auto, 100%;
	}
  /* .answers > div {
    margin: 0 20px;
  } */
    .mwaheb-field {
        width: 110px !important;
        padding: 0;
        /* margin: 0 20px; */
    }
    /* .ckb-text > .mwaheb-select{
      margin-left: -10px !important;
    } */
    .date-birth{
    border: none;
    outline: none;
    background-color: #fff;
    -moz-appearance: none;
    -webkit-appearance: none;
    /* background-image: url(../images/date-icon.svg); */
    background-repeat: no-repeat, repeat;
    background-position: left 96% top 50%, 0 0;
    background-size: 1.5em auto, 100%;    
    } 
.dob_options_container{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.textfield {
  height: 51px ;
}
/* ---- Placeholders Floating to the top Register---- */
 
.reg-field , .reg-field-tel , .field{
  position: relative;
  /* margin-bottom: 2rem; */
}

input {
  border: 0;
  border-bottom: 2px solid #ccc;
  font-size: inherit;
  padding: 0;
  outline: none;
}

input::placeholder {
  color: transparent;
}
textarea::placeholder {
  color: transparent;
}
input:focus::placeholder , textarea:focus::placeholder{
  color: initial;
}
input:focus + label{
  top: -17px;
}
input[type=text]:disabled {
  background-color: #f8f8f8 !important;
  border: 1px #bebcbc solid !important;
  -webkit-text-fill-color: #878484;
  color: #878484;
  opacity: 1;
  -webkit-opacity: 1;  
}
.reg-field label {
  transition: all 0.3s ease;
  pointer-events: none;
  position: absolute;
  background-color: #fff;
  top: 10px;
  left: 40px;
  cursor: text;
  z-index: 3;
  color: #A9A9A9;
  font-weight: normal;
  font-size: 19px;
  padding: 0 5px;
}
.reg-field-tel label {
    transition: all 0.3s ease;
    pointer-events: none;
    position: absolute;
    background-color: #fff;
    cursor: text;
    left: 100px;
    top: 11px;
    z-index: 3;
    color: #A9A9A9;
    font-weight: normal;
    font-size: 19px;
    padding: 0 5px;
}
.reg-field:has(input:not(:placeholder-shown)) label,
.reg-field:has(input:focus) label,
.reg-field:has(textarea:not(:placeholder-shown)) label,
.reg-field:has(textarea:focus) label {
  top: -17px;
  pointer-events: initial;
}
/* test */
.non-empty{
  top: -17px !important;
}
.reg-field-tel:has(input:not(:placeholder-shown)) label,
.reg-field-tel:has(input:focus) label {
  top: -17px;
  pointer-events: initial;
}
.ckb-text{
  line-height: 27px;
  color: #999999; 
  /* margin-left:-75px; */
}
.remove-child {
  width: 90%;
  text-align: left;
  font-size: 23px;
  margin: 40px auto 15px !important;
}
.remove-child > a {
  color: #f29221;
}
/* ---- Placeholders Floating to the top Login---- */
 
.field label {
  transition: all 0.3s ease;
  pointer-events: none;
  position: absolute;
  background-color: #fff;
  top: 7px;
  left: 40px;
  cursor: text;
  z-index: 3;
  color: #A9A9A9;
  font-weight: normal;
  font-size: 23px;
  padding: 0 5px;
  -webkit-top: 7px; 
  -moz-top: 7px ; 
   -ms-top: 7px; 
}

.field:has(input:not(:placeholder-shown)) label,
.field:has(input:focus) label {
  top: -17px;
  pointer-events: initial;
  -webkit-top:-17px !important; 
  -moz-top: -17px  !important; 
   -ms-top: -17px !important; 

}

input.login-input:focus{
  -webkit-top : -17px;
  -moz-top: -17px; 
  -ms-top: -17px !important; 

}
/*for FireFox*/
input[type="text"]::-moz-focus-inner,
input[type="password"]::-moz-focus-inner {top : -17px; }

/*for IE8 */
/* input[type="text"]:focus,
input[type="password"]:focus { top : -17px; }
 */
input[type=checkbox].ckb:checked + label::before, input[type=radio].ckb:checked + label:hover{
  background-color:#579683;
  transition: background-color .2s ease-in, color .2s ease-in;
}
.joinus-textarea{
  height: 130px;
  width: 100% !important;
}
.pay-button {
  background-color: #fff;
  color: #F39422;
  outline: none;
  display: block;
  text-align: left;
  cursor: pointer;
  padding: 10px 0px 4px 0px;
  font-size: 16px;
  border-bottom: 1px solid #F39422;
}
.paymentPlan-btns{
  text-align: left;
  padding: 10px 15px 4px 15px;
}
.paymentPlan-note{
  padding: 10px 15px 4px 15px;
  font-size: 16px;
  color: #c5c5c5;
  text-align: left;

}
.reg-field > .userReg-input{
  direction: ltr;
  text-align: left;
}
.joinus-field > #mobile {
  padding: 10px 105px !important;
}
.joinus-field > .joinus-field-mob{
  left: 100px !important;
}
.mob-prefix{
  left :0 !important;
  font-size: 19px !important;
  right: unset !important;
}
.placeholder-mob{
  left: 60px !important;
}
/* Campaigns forms */
.camp-form > form > .form-group > .reg-field > .reg-input {
  direction: ltr;
  padding: 10px 20px !important;
}
/* Campaigns forms */
.camp-form > form > .form-group > span.toggle-password {
  right: 35px !important;
  left: unset;
  font-size: 16px !important;
}
.redirect-camp{
  text-align: center;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4rem;

}
.redirect-camp:hover{
 color: #F39422;
}
.camp-btn{
  margin: 4rem auto 1rem !important;
}
.child-mob > #mobile {
  padding: 10px 100px !important;
}
@media(max-width:992px){
  ._4-sentence {
    font-size: 25px;
  }
  .placeholder-mob{
    left: 50px !important;
  }
  .content-container{
    width: 100% !important;
  }
  .reg-form {
    width: 100%;
}
.inline-group {
  display: block;
  width: 100%;
}
.reg-field > .userReg-input{
  direction: ltr;
  padding: 8px 10px !important;
  text-align: left;
}
.inline-group > .col-md-6:nth-child(1) {
  padding-left: 0px !important;
  padding-right: 0;
  margin-top:30px;
}
.inline-group > .col-md-6:nth-child(2) {
  padding-right: 0px;
  padding-left: 0;
  margin-top:30px;
}
.reg-input,.reg-input-2 {
  font-size: 19px !important;
  /* padding: 5px 42px !important; */
  padding: 10px 20px !important;
}
.reg-field label {
  top: 8px;
  font-size: 19px;
  left: 25px;
}
.reg-field-tel label {
  top: 10px;
  font-size: 19px;
}
.joinus-field > #mobile {
  padding: 10px 90px !important;
}
.joinus-field > .joinus-field-mob {
  left: 85px !important;
}
#mobile {
  padding:8px 5px 7px 105px !important
}
.input-checkbox[type=checkbox].ckb + label {
  font-size: 19px !important;
  padding: 0;
}
.form-select {
  font-size: 17px;
  height: 44px;
}
.select-role > p{
  width: 55%;
  text-align: left;
}
.child-num{
  display: flex;
    justify-content: center;
    width: 45%;
    max-width: 130px;
}
.reg-input > select {
  font-size: 19px;
}
span.toggle-password {
  top: 12px;
}
.reg-input > select {
  background-position: left 100% top 50%, 0 0;
  background-size: 11px auto,100%;
  margin-left: 0 !important;
  width: 100% !important;
}
.mwaheb-field {
  padding:8px 10px !important;
  width: 145px !important;
}
.reg-title {
  padding-top: 20px;
  width: 90%;
  margin: 0 auto;
  font-size: 23px;
}
.date-label > label {
  font-size: 19px;
}
.dob_options_container {
  margin: 0 auto;
  width: 100% !important;
  display: flex;
  justify-content: space-between !important;
}
.dob_options_container > .col-md-3 {
  width: 30% !important;
  padding-left: 0 !important;
}
.reg-input {
   /* padding: 8px 28px !important; */
}
.child-mob > #mobile {
  padding: 10px 90px !important;
}
.custom-input{
  padding: 8px 28px !important;
}
.date-label{
  width: 100% !important;
}
.select-date {
  background-position: left 90% top 50%, 0 0;
  background-size: 11px auto,100%;
}
.checking > div > label{
  margin-top: -40px;
}
.select-role {
  font-size: 19px;
}
.answers:not(:first-child) {
  display: flex;
  justify-content: center;
  width: 45%;
  max-width: 130px;
}
.answers > div {
  width: 90px;
  font-size: 19px;
  margin: 0 10px;
}
.pay-info{
  display: flex !important;
}
.reg-field > .userReg-field{
  padding: 8px 15px !important;
}
/* Campaigns forms */
.camp-form > form > .form-group > .reg-field > .reg-input {
  padding: 8px 20px !important;
}
.joinus-input .reg-input {
  padding: 8px 20px !important;
}
}
@media(max-width:700px){
  .otp-form {
    gap: 30px;
    flex-direction: column-reverse;
    align-items: flex-end;

  }
  .camp-btn {
    margin: 1.5rem auto 1rem !important;
}
  .smiley-icon{
    display: none;
  }
}
@media(max-width:560px){
  .pay-label{
    font-size: 16px !important;
  }
  .pay-info-field {
    font-size: 16px;
}
.wrapper1 {
  font-size: 16px;
  padding: 5px;
}

._1-sentence {
  font-size: 25px;
}
}
@media(max-width:514px){
  .input-checkbox[type=checkbox].ckb + label {
    display: inline-flex;
    justify-content: flex-start !important;
    font-size: 15px !important;
}
.reg-form{
  padding-bottom: 20px;
}
}
@media(max-width:400px){
  .mob-prefix{
    font-size: 16px !important;
  }
  .reg-field label , .date-label > label {
    font-size: 16px;
    top: 8px;
}
 .reg-input,.reg-input-2 {
  font-size: 19px !important;
}
.error {
  font-size: 13px !important;
}
.form-select {
  height: 40px;
}
span.toggle-password {
  top: 10px;
}
.reg-field-tel label {
  top: 8px;
}
}
@media(max-width:370px){
  .submit-button-auto {
    width: 80%;
}

}
@media(max-width:319px){
  .reg-field label , .date-label > label{
    font-size: 13px;
    top: 8px;
}

.error , .reg-input, .reg-input-2{
  font-size: 13px !important;
}
}
@-moz-document url-prefix() {
  .field label, .reg-field label , .reg-field-tel label{
    top: -17px;
  }
}