.course-module-container {
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    background-color: #e9e9e9;
    flex-direction: column-reverse;
}
.course-modules-menu {
    position: relative;
    width: 100%;
    height: 100%;
    /* padding-top: 60px; */
    /* background-color: #1d1e22; */
    overflow: hidden;
    /* padding-bottom: 50px; */
    transition: width 0.5s;
}
.course-modules-menu.opened{
    width: 25px;    
}
.btn-success {
    border-radius: 0 20px 0 20px;
    font-size: 18px;
    background-color: #6bcdb2;
    outline: 0;
    border: none;
}

.menu-shader{
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    /* background-color: #1d1e22; */
    z-index:100;    
    
}
.menu-handler{
    color: white;
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 2rem;
    cursor: pointer;    
    z-index: 200;
}
.panel{
    background-color: transparent;
    border: none;
}
.panel-group .panel{
    border-radius: 0;
    /* background-color: #1d1e22; */
}

.panel-default {
    /* border-color: unset; */
}

.panel-default>.panel-heading {
    color: #dddfdd;
    /* background-color: #1d1e22; */
    /* border-color: #1d1e22; */
}

.list-group-item {
    color: #b6b6b6;
    position: relative;
    display: block;
    padding: 0px;
    margin-bottom: -1px;
    /* background-color: #1d1e22; */
    border: 0;
    border-radius: 0;
    
}

.azuremediaplayer {
    background-color: transparent !important;
}
.mp-component_azure-mp-container__idgeq {
    background: transparent !important;
}
.panel>.list-group:last-child .list-group-item:last-child, .panel>.panel-collapse>.list-group:last-child .list-group-item:last-child {
    border: 0;
    border-radius: 0;
}
.panel-group .panel-heading+.panel-collapse>.list-group, .panel-group .panel-heading+.panel-collapse>.panel-body {
    border: 0;
}
.list-group {
    /* margin-left: 50px; */
    border: 0;
    border-radius: 0;
}
.download-icon{
    display: flex;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
}
.list-group-item {
    display: flex;
    justify-content: space-between;
}
.list-group-item.selected{
    /* background-color:#fab566; */
    color:#F39422 !important;
}

.module-right-side{
    width: 100%;
    text-align: center;
}
.module-right-side video{
    width: 100%;
    /* max-width: 1500px; */
}


.module-contents{
    position: relative;
    /* width:700px; */
    max-width: 95%;
    margin: 0 auto;
}
.mark-complete-btn {
    position: absolute;
    display: none;
    top: 00px;
    right: 0px;
    z-index: 90;
}
.module-contents > .mp-component_azure-mp-container__idgeq > .amp-big-play-centered > .vjs-player > .vjs-big-play-button {
    color: #999999;
    height: 90px !important;
    width: 90px !important;
    background-color: rgba(60,69,79,.7) !important;
}
.module-contents > .mp-component_azure-mp-container__idgeq > .amp-big-play-centered > .vjs-player > .vjs-big-play-button:before {
font-size: calc(.06em) !important;
}
.lesson-content-list li{
    margin-top:50px;
    padding-bottom: 10px;
    font-weight: bold;
    position: relative;
}
.download-material {
    background-color: #F39422;
    color: #fff;
    display: flex;
    padding: 7px 20px;
    font-size: 23px;
    margin: 0 auto;
    width: 270px;
    border-radius: 20px;
    justify-content: space-around;
    align-items: center;
}
.download-material > a{
    color: #fff !important;
}
.module-lang-swicher{
    width: 158px;
    height: 158px;
    position: absolute;
    right: -76px;
    top: 3%;
    overflow: hidden;
    text-transform: uppercase;
    font-size: 22px;
    cursor: pointer;
    transform: scale(0.9);
}
.module-lang-swicher > span {
    width: 150px;
    height: 40px;
    line-height: 33px;
    position: absolute;
    top: 45px;
    right: -19px;
    z-index: 2;
    overflow: hidden;
    transform: rotate(-90deg);
    background: #aeaeae;
    text-align: center;
    color: #cdcdcd;
}
.module-lang-swicher > span.active-module-lang {
    background: #65c5aa;
    color: #fff;
}
.module-lang-swicher:nth-child(1){
    top: 20px;
}
.module-lang-swicher:nth-child(2){
    top: 160px;
}


/* Accordion styles */

.level-accordion {
    margin: 0 auto;
    max-width: 95%;
    width: 700px;
    color: white;
    overflow: hidden;
    background-color: #fff;
    border-radius: 30px;
    margin-bottom: 40px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}   
.level-acc-label {
    display: flex;
    font-size: 23px;
    justify-content: space-between;
    padding: 0.5em 1em;
    background-color: #6bcdb2;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 0px;
    color: #fff;
    border-radius: 60px;
    font-family: "STC" !important;
    align-items: center;
}
  
.level-acc-label::after {
    font-family: 'FontAwesome';
    font-weight: 900;
    content: "\f107";
    width: 1em;
    font-size: 20px;
    height: 1.3em;
    text-align: center;
    transition: all 0.4s ease;
}
   
.episode-input-accordion {
    position: absolute;
    opacity: 0;
    z-index: -1;
    font-family: "STC" !important;
}
   
.episode-input-accordion:checked+.level-acc-label::after {
    transform: rotate(-180deg);
}

.episode-input-accordion:checked~.acc-level-content {
    max-height: 50vh;
    border-top: none;
    overflow-x: auto;
    border-radius: 0 0 25px 25px;
}

input[type=checkbox].ckbox + label, input[type=radio].ckbox + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    padding: 0 45px 0 35px;
    cursor: pointer;
    height: 60px;
    width: 70px;
    font-weight: normal;
    border-radius: 0;
    border: none;
    transition: all 0.3s;
    color: #999999;
    justify-content: center;
}

input[type=checkbox].ckbox:checked + label::before {
    transition: all 0.3s;
    font-family: 'FontAwesome';
    content: "\f00c";
    color: #f49422 !important;
    background-image: none !important;
    background-color: transparent !important;
    border:none;
    box-shadow: none !important;
    font-size: 20px;
    font-weight: bolder;
}

input[type=checkbox].ckbox + label::before{
    border: none !important;
}

input[type=checkbox].ckbox:checked + label::after{
    background: none;
}

.episode-name{
    cursor: pointer;
    font-size: 25px;
    margin: 0;
    /* color: #b6b6b6; */
}

.episode-container{
    display: flex;
    align-items: center;

}

a:focus, a:hover {
    /* color: #fff; */
    text-decoration: none;
}
.episode-input-accordion:checked+.acc-level-content {
    max-height: 100vh;
    padding: 1.5em;
    /* border: 1px solid #b6b6b6; */
    border-top: none;
    border-radius: 0 0 25px 25px;
}
.acc-level-content {
    max-height: 0;
    padding: 0 1em;
    color: #676767;
    background-color: #fff;
    transition: all 0.4s ease;
}
.material > li > a > div {
    display:none;
}
.material > li > a{
visibility: hidden;
position: relative;
}
.material > li > a::after {
    visibility: visible;
    position: absolute;
    top: 0;
    width: 200px;
    left: -45px;
    color: #fff;
    cursor: pointer;
    content: "Download Material";
}
@media(max-width: 800px){
    .module-lang-swicher {
        transform: scale(0.7);
    }
    .module-lang-swicher:nth-child(2) {
        top: 120px;
    }
}
@media(max-width: 600px){
    .episode-name {
        font-size: 20px;
    }
    .module-lang-swicher {
        transform: scale(0.6);
    }
    .module-lang-swicher:nth-child(2) {
        top: 100px;
    }
}
@media(max-width: 480px){
    .episode-name {
        font-size: 20px;
    }
    .module-lang-swicher {
        transform: scale(0.5);
        top: -20px;
    }
    .module-lang-swicher:nth-child(2) {
        top: 55px;
    }
}