@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap");
.credit-card-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  
  margin: 0;
  font-family: "Lato";
  font-weight: 300;
  font-size: 24px;  
  background-position: center center;
  background-size: 200%;
  background-blend-mode: multiply;
}

h2 {
  display: block;
  color: white;
  font-weight: 300;
  text-align: center;
}

.credit-card {
  font-family: "Share Tech Mono", monospace;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0.5rem;
  backdrop-filter: blur(4px);
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-row-gap: 0.5rem;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 0.35rem;
  grid-template-areas: "provider . . ." "number number number number" "good-through-label good-through-value . validity" "holder holder holder select";
}
.credit-card > .provider {
  grid-area: provider;
}
.credit-card > .number {
  grid-area: number;
}
.credit-card > .good-through-label {
  grid-area: good-through-label;
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  font-size: 0.65rem;
}
.credit-card > .good-through-value {
  grid-area: good-through-value;
}
.credit-card > .holder {
  grid-area: holder;
}
.credit-card > .validity {
  grid-area: validity;
  border: 1px solid #44a333;
  font-size: 1rem;
  background: #57c443 linear-gradient(45deg, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.25) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 3px 6px 0px rgba(0, 0, 0, 0.5);
}
.credit-card > .select {
  grid-area: select;
  background: #2ca7d8 linear-gradient(45deg, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.25) 100%);
  border: 1px solid #1482ce;
  color: white;
  font-family: "Lato", sans-serfi;
  font-size: 1rem;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 3px 6px 0px rgba(0, 0, 0, 0.5);
  transition: box-shadow 250ms, transform 250ms;
}
.credit-card > .select:active {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  transform: translate(-1px, -1px);
}