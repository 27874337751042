.infinite-scroller {
    width: 30%;
    margin: auto;
    overflow: hidden;
}
.infinite-scroller ul {
    display: flex;   
    padding: 0;
    animation: cambio 10s infinite linear;
    
}
.infinite-scroller li {
    padding:0 10px;
    list-style: none;
}
.infinite-scroller img {
    max-width: fit-content !important;
    /* height: auto !important; */
    width: 60px;
    height: 60px;
}
@keyframes cambio {
    from {margin-left: 0%}
    to {margin-left: -30%}
}