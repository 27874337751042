.camp-theme-logo-container{
    display: flex;
    justify-content: flex-end;
}

.camp-theme-logo {
margin: 30px 30px 0 30px;
width: 100%;
max-width: 150px;
}
.theme-container{
background-image: url(/public/assets/images/theme_images/background.png);
background-size: cover;
background-repeat: no-repeat;
}
.theme-section {
display: flex;
flex-direction: column;
/* justify-content: center; */
}
.camp-step1-form{
margin: 0 auto;
width: 80%;
}
.camp-title{
display: block;
text-align:left;
color: #4d2b85;
font-weight: bold;
font-size: 35px;
margin: 0;
}
.camp-title:nth-child(1){
margin-top: 30px;
}
.camp-text{
color:#6bcdb2;
font-size: 22px;
text-align: left;
direction: ltr;
margin-top: 30px;
}
.mobile-camp-pic-box{
display: none !important;
}
.camp-text2{
color:#4d2b85;
font-size: 24px;
text-align: left;
direction: ltr;
margin-top: 30px;
font-weight: bold;
}
.camp-theme-form{
width: 50%;
margin: 0;
}
.camp-theme-form2{
width: 50%;
margin: 0;
}
.camp-pic-box{
width: 50%;
max-width: 800px;
display: flex;
justify-content: flex-end;
align-items: center;
}
.campTheme-video{
border-radius: 23px 23px 0 0;
width: 100%;
display: block;
margin: 0 auto;
}
.camp-pic{
display: block;
width: 80%;
transform: scaleX(-1);
float: right;

}
.camp-step1-form > form {
width: 100%;
margin: 5rem auto 40px auto;
}
.camp-step1-form > form >  button {
width: 100% !important;
/* margin: 6rem auto 1rem !important; */
}
.camp-theme-form2 > .stripe-container{
border-radius: 40px;
border: 1px solid #6bcdb2;
padding: 30px;
width: 500px;
margin: 20px auto 20px auto;
}
.camp-theme-form2 > .stripe-container > .stripe-form > .FormGroup{
border-radius: 40px;
border: 1px solid #6bcdb2; 
box-shadow: none;
margin-bottom: 40px;
width: 100%;
margin: 0px auto 30px 0;
}

.camp-theme-form2 > .stripe-container > .stripe-form > .wizard-btns-container > button{
margin: 0rem auto 0rem;
width: 200px !important;
font-size: 19px;
}
.camp-theme-form2 > .stripe-container > .stripe-form > .FormGroup > .FormRow > .FormRowLabel{
/* padding: 11px 15px 11px 0; */
}
.campTheme-step2Text{
color: #6bcdb2;
font-size: 20px;
display: block;
width: 90%;
margin: 0 auto;
text-align: left;
padding-top: 25px;
direction: ltr;
}
.campTheme-step2Title{
color: #4d2b85;
font-size: 23px;
font-weight: bold;
display: block;
width: 90%;
margin: 0 auto;
text-align: left;
padding-top: 25px;
direction: ltr;
}
.campTheme-step2Title:nth-child(2){
padding-top: 0px;
}
.campForm-text{
font-size: 14px;
color: #6bcdb2;
text-align: center;
margin: 0 0 20px;
width: 95%;
margin: 0 auto;
}


.camp-theme-form2 > .cards {
    background-color: #e9e9e9;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin-top: 20px;
}
.camp-theme-cards{
    background-color: #e9e9e9;
    transform: scale(0.9);
    padding: 20px 0;
    border-radius: 20px;
}
.camp-theme-cards > .tab-content > .tab-pane > .p-card-container > .p-card-plan{
    font-size: 2.5rem;
    padding: 3rem 2rem 0rem 2rem;
}
.camp-theme-cards > .tab-content > .tab-pane > .p-card-container > .p-card-filled{
    height: 120px;
}
.camp-theme-cards > .tab-content > .tab-pane > .p-card-container > .p-card-top-icon{
    left: 32%;
}
.p-swicher-camp{
    margin-bottom: 10px !important;
    margin-top: 30px;
}



.otp-camp-theme{
display: block;
margin: 0 auto;
width: 85%;
}
.otp-camp-theme > .otp-form{
padding: 20px 0;
}
.camp-theme-form > form > .form-group > .reg-field > input#email{
direction: ltr;
}
.theme-step4{
z-index: 200;
position: relative;
}
.camp-theme-form4 {
max-width: 500px;
width: 80%;
background-color: #fff;
margin: 20px auto;
border-radius: 40px;
padding: 20px 20px;
max-height: 300px;
height: 100%;
}
.camp-theme-form4 > .smiley-icon{
margin: 0 auto;
display: block;
}
.camp-theme-form4 > .close-userReg{
width: 100% !important;
justify-content: flex-start !important;
}
.camp-theme-form4 > .close-userReg > span{
color: #6bcdb2;
}
.camp-theme-form4 > .camp-text {
color: #6bcdb2;
font-size: 25px;
text-align: center;
margin-top: 30px;
}
.camp-disclaimer{
display: block;
margin: 0 auto;
width: 80%;
padding-bottom: 30px;
}
.camp-disclaimer > .campForm-text{
text-align: left;
font-size: 13px;
margin-bottom: 0;
color: #b9d4cd;
}
.camp-theme-form2 > .stripe-container > .stripe-form > .ErrorMessage {
margin-bottom: 30px;
}
.camp-step1-form > form > .form-group > .campTheme-byGoogle {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 33px;
    padding: 0 !important;
}
.camp-step1-form > form > .form-group > .campTheme-byGoogle > p {
    width: 90%;
    border-left: 1px solid rgb(107, 205, 178);
    height: 100%;
    margin: 0 12px 0 0;
    color: #6bcdb2;
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.camp-step1-form > form > .form-group > .campTheme-byGoogle > p > span {
    display: block;
    text-align: center;
}
.camp-step1-form > form > .form-group > .campTheme-byGoogle > img {
    width: 10%;
    max-width: 30px;
    margin: 3px 5px;
}
.reg-by{
color: rgb(107, 205, 178);
margin: 0 auto;
display: block;
text-align: center;
background-color: #fff;
position: absolute;
left: 48%;
padding: 0px 10px;
font-size: 17px;
margin-top: -15px;
}
.social-login-container > img {
cursor: pointer;
}
.img-data-container{
border: 2px solid #6bcdb2;
max-width: 400px;
width: 100%;
margin: 0 auto;
border-radius: 25px;
}
.img-data-container> img{
border-radius: 25px 25px 0 0;
width: 100%;
background-color: #fff;
box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
}
.text-under-img {
border-radius: 0px 0px 20px 20px;
background-color: #6bcdb2;
color: #fff;
text-align: center;
font-size: 18px;
padding: 3px 0;
}
.camp-description{
width: 82%;
margin: 0 auto;
color: #6bcdb2;
font-size: 20px;
text-align: left;
direction: ltr;
padding: 30px 0;
}
.camp-cards {
width: 75%;
display: flex;
justify-content: space-between;
margin: 30px auto 30px auto;
}
.camp-card{
display: flex;
width: 30%;
flex-direction: row;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
background-color: #fff;
padding: 15px;
border-radius: 15px;
}
.camp-card > img{
width: 50px;
height: 50px;
}
.camp-card > p {
direction: ltr;
text-align: left;
padding: 0 10px;
display: flex;
margin-bottom: 0;
/* align-items: center; */
font-size: 14px;
flex-direction: column;
justify-content: flex-start;
}
.camp-card > p > strong{
font-size: 15px;
}
.camp-list-desc{
color: #6bcdb2;
font-size: 16px;
padding: 0 10px;
}
.camp-list-desc ol{
display: flex;
}
.camp-list-desc ol span{
margin: 0 5px;
}
.camp-accordions{
width: 100%;
margin: 0 auto;
}
.camp-accordions > .accordion{
width: 100% !important;
max-width: 100% !important;
border-radius: 0 !important;
margin-bottom: 0 !important;
}
.camp-accordions > .accordion > .acc-label{
border-radius: 0 !important;
background-color: #eff8f5;
color: #6bcdb2;
border-top: 1px solid #6bcdb2;
}
.camp-accordions > .accordion > .input-accordion:checked~.acc-content{
border-radius: 0 !important;
border: 0 !important;
/* font-size: 15px; */
}
.camp-accordions > .accordion > .acc-content > p {
margin: 0 !important;
}
.camp-accordions > .accordion > .acc-content{
max-height: 0px !important;
}
.camptheme-content-title:nth-child(1){
color: #4d2b85;
font-size: 17px;
font-weight: bold;
padding: 15px 0 0 0;
}
.camptheme-content-title:nth-child(2){
color: #6bcdb2;
}
.camp-theme-form > .social-login-container{
margin-bottom: 0 ;
}
.camp-accordions .accordion > .input-accordion:checked~.acc-content {
max-height: 150vh !important;
}
.theme-section2 {
display: flex;
flex-direction: row;
justify-content: flex-end;
}
.step2-section{
display: flex;
flex-direction: row;
justify-content: space-between;
width: 90%;
margin: auto 0 0 auto;

}
.reg-button{
padding: 8.55px 0px;
border: 1px solid #6bcdb2; 
border-radius: 25px;
cursor: pointer;
display: flex;
justify-content: center;
flex-direction: row-reverse;
background-color: #eff8f5;
}
.reg-button > p{
margin: 0 12px 0 0;
color: #6bcdb2;
font-size: 19px;
}
.camp-hr-line{
border-bottom: 1px solid #6bcdb2;
margin-top: 15px;
margin-bottom: 20px;
}
.campThemeInput{
border: 1px solid #6bcdb2 !important;
}
.campThemeLabel, .campEye{
color: #6bcdb2 !important;
-webkit-text-fill-color: #6bcdb2 !important;
}
.reg-field label[class='campThemeLabel']{
    top: 5px;
    font-size: 16px;
}
.campEye{
    top: 7px !important;
}
.campThemeInput:-webkit-autofill, .campThemeInput:-webkit-autofill:hover, .campThemeInput:-webkit-autofill:focus, .campThemeInput:-webkit-autofill:active{
color: #6bcdb2 !important;
-webkit-text-fill-color: #6bcdb2 !important;
}
.campThemeBtn{
margin: 7rem auto 1rem !important;
}
/* new accordion */

/* Core styles/functionality */
.tab input {
position: absolute;
opacity: 0;
z-index: -1;
}
.tab__content {
max-height: 0;
overflow: hidden;
transition: all 0.35s;
}
.tab input:checked ~ .tab__content {
max-height: 150vh;
padding: 20px;
background-color: #fff;
direction: ltr;
}

/* Visual styles */
.camp-accordion {
overflow: hidden;
padding-top: 50px;

}
.tab__label,
.tab__close {
display: flex;
align-items: center;
color: #6bcdb2;
background: #eff8f5;
cursor: pointer;
}
.tab__label {
justify-content: space-between;
padding: 1.5rem;
font-size: 20px;
margin: 0 !important;
border-top: 1px solid #6bcdb2;
font-weight: normal;
}
.tab__label::after {
content: "\276F";
width: 1em;
text-align: center;
transform: rotate(90deg);
transition: all 0.35s;
}
.tab input:checked + .tab__label::after {
transform: rotate(270deg);
}
.tab__content p {
margin: 0;
padding: 1rem;
}
.tab__close {
justify-content: flex-end;
padding: 0.5rem 1rem;
font-size: 0.75rem;
}
.accordion--radio {
color: #4d2b85;
}
.camp-step1-form > form > .form-group > .reg-button{
    padding: 3.55px 0px;
}

.camp-step1-form > form > .form-group >.reg-field > input{
    padding: 5px 43px !important;
    height: 33px;
    font-size: 16px !important;
}
.camp-step1-form > form > button {
    margin: 1.2rem auto 1rem !important;
    padding: 0px !important;
}
/* Arrow animation */
.tab input:not(:checked) + .tab__label:hover::after {
animation: bounce 0.5s infinite;
}
@keyframes bounce {
25% {
  transform: rotate(90deg) translate(0.25rem);
}
75% {
  transform: rotate(90deg) translate(-0.25rem);
}
}

@media(max-width:992px){
.video-camp {
    display: none;
}
.camp-theme-logo {
    margin: 30px 30px 30px 30px ;
    max-width: 100px;
}
.theme-section2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.step2-section {
    flex-direction: column;
    width: 100%;
}
.mobile-camp-pic-box{
    display: block !important;
    margin: 0px auto 0 auto;
}
.camp-theme-form2 > .stripe-container{
    width: 90%;
}
.camp-theme-form {
    width: 100%;
    margin: 0 auto;
}
.camp-step1-form > form{
    margin: 3rem auto 1rem auto;
}
.camp-step1-form > form >  button {
    margin: 1rem auto 1rem !important;
    padding: 5px 42px !important;
}
.camp-step1-form > form > .form-group > .reg-field > input{
    padding: 5px 42px !important;
}
.camp-title {
    font-size: 25px;
    /* padding-top: 20px; */
}
.img-data-container {
    width: 90%;
}
.camp-text , .camp-step1-form > form{
    width: 90%;
    margin: 0 auto;
    font-size: 18px;
}
.camp-step1-form > form .camp-hr-line {
    margin-top: 10px;
    margin-bottom: 10px;
}
.reg-field label[class='campThemeLabel']{
    top: 4.3px;
}
.camp-pic{
    width: 250px !important;
    float: none;
    margin: 0 0 auto auto;
}
.mobile-camp-pic-box{
    width: 90% !important;
}
.camp-title:nth-child(1) , .camp-title:nth-child(2){
width: 90%;
margin:10px auto 10px auto;
font-size: 20px;
}
.camp-step1-form > .camp-pic-box .img-data-container {
    width: 100% !important;
}
.camp-theme-form2 {
    width: 100%;
    margin: 0 auto;
}
.camp-pic-box {
    width: 100%;
}
.camp-step1-form > form .form-group .reg-button{
    padding: 3px 0px;
}
.camp-step1-form > form > button {
    padding: 0px !important;
}
.camp-text2 {
    font-size: 15px;
    width: 90%;
    margin: 0 auto;
}
.text-under-img {
    border-radius: 20px 20px 0 0;
}
.campTheme-video{
    border-radius: 23px;
}
.camp-theme-form2 > .stripe-container {
    margin: 1rem auto 10px auto !important;
}
/* .camp-accordions > .accordion > .input-accordion:checked~.acc-content {
    font-size: 13px;
} */
.camp-pic-box {
    /* width:90%; */
    margin: 15px auto 0 auto;
}
.campThemeBtn{
    margin: 4rem auto 1rem !important;
}
/* .campEye {
    top: 9px !important;
} */
.reg-by {
    left: 45%;
}
.camp-cards {
    flex-direction: column;
    margin: 0px auto 30px auto;
}
.camp-card {
    width: 100%;
    margin: 5px 0 5px 0;
}
.camp-description {
    width: 88%;
    padding-top: 20px;
}
.tab__label {
    padding: 1rem;
    font-size: 17px;
}
.camp-accordion {
    padding-top: 10px;
}
.camp-step1-form {
    width: 100%;
}
.reg-button {
    align-items: center;
}
.reg-button > p {
    font-size: 19px;
}
.mawaheb-Logo-theme{
    display: none;
}
}
@media(max-width:400px){
.reg-button > p {
    font-size: 16px;
}
.campTheme-step2Title {
    font-size: 17px;
}
.campTheme-step2Text {
    font-size: 15px;
    padding-top: 10px;
}
.camp-theme-logo {
    max-width: 100px;
}
.camp-step1-form > form > .form-group{
    margin-top: 20px !important;
}
}
