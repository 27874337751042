/* FAQS STYLE */
/* #root{
 background-color: #e9e9e9 !important;
} */
.faqs-container{
    background-color: #e9e9e9;
    padding-bottom: 50px;
}
.faqs-image{
    width: 100%;
    background-color: #fff;
}
.faqs-image > h1 {
    position: absolute;
    color: #676767;
    left: 180px;
    top: 250px;
    padding: 20px;
    margin: 0 !important;
}   

.faqs-image > img {
    width: 80rem;
    max-width: 100%;
    display: block;
    margin: 0 auto;
}

.accordions-container {
    width: 85%;
    padding: 50px 0;
    margin: 0px auto;
    /* background-color: #e9e9e9; */
}

.accordions {
    overflow: hidden;
    font-size: 1.2em;
    /* margin-bottom: 10rem; */
    background-color: #fff;
    padding: 55px 20px 20px 20px;
    border-radius: 50px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.accordion {
    margin: 0 auto;
    max-width: 95%;
    width: 700px;
    color: white;
    overflow: hidden;
    border-radius: 25px;
    margin-bottom: 40px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.acc-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.6em 1em;
    background-color: #6bcdb2;
    font-weight: 500;
    cursor: pointer;
    font-size: 23px;
    margin-bottom: 0px;
    color: #fff;
    border-radius: 25px 25px 0 0;
    font-family: "STC" !important;
}

.acc-label:hover {
    background-color: #5dccac;
    transition: background-color .2s ease-in, color .2s ease-in;
}
   
.acc-label::after {
    font-family: 'FontAwesome';
    font-weight: 900;
    content: "\f107";
    width: 1em;
    font-size: 20px;
    height: 1.3em;
    text-align: center;
    transition: all 0.4s ease;
}

.acc-content {
    max-height: 0;
    padding: 0 1em;
    color: #676767;
    background-color: #fff;
    transition: all 0.4s ease;
}

.input-accordion {
    position: absolute;
    opacity: 0;
    z-index: -1;
    font-family: "STC" !important;
}

.input-accordion:checked+.acc-label::after {
    transform: rotate(-180deg);
}

.input-accordion:checked~.acc-content {
    max-height: 100vh;
    padding: 1em;
    font-size: 21px;
    padding: 1em;
    border: 1px solid #b6b6b6;
    border-top: none;
    border-radius: 0 0 25px 25px;
}
   @media(max-width:992px){
    .accordions-container {
        width: 90%;
        padding: 30px 0 0 0;

    }
    .accordions {
        overflow: hidden;
        font-size: 1.2em;
        background-color: #e9e9e9;
        padding: 8px 0 0 0;
        border-radius: 50px;
        box-shadow: none;
        margin-bottom: 0;
    }
    .input-accordion:checked~.acc-content {
        border: none;
        border-top: none;
        color: #969696;
    }
    .input-accordion:checked~.acc-content {
        font-size: 18px;
    }
    .acc-label {
        font-size: 20px;
    }
    .faqs-container {
        padding-bottom: 0;
    }
    .faqs-image > h1 {
        font-size: 25px;
        text-align: center;
        color: #999999;
        left: 180px;
        top: 250px;
        position: unset;
        padding: 0px 0 30px 0;
        margin: 0 !important;
    }
    .faqs-image > img {
        content: url(../../../public/assets/images/mob_fags.png);   
        width: 100%;
        max-width: 300px;
 }
 .faqs-image {
    width: 100%;
    height: 236px;
    display: flex;
    background-color: #fff;
    flex-direction: column-reverse;
}
}
 
   