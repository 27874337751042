
.stripe-container {
  width: 600px;
  max-width: 100%;
  margin: 50px auto;
}

.stripe-container input,
.stripe-container button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border-style: none;
}


@keyframes fade {
  from {
    opacity: 0;
    transform: scale3D(0.95, 0.95, 0.95);
  }
  to {
    opacity: 1;
    transform: scale3D(1, 1, 1);
  }
}

.stripe-form {
  animation: fade 200ms ease-out;
}

.stripe-container .FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  /* background-color: #fff; */
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #999999;
  border-radius: 4px;
}

.stripe-container .FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid #999999;
}

.stripe-container .FormRow:first-child {
  border-top: none;
}

.stripe-container .FormRowLabel {
  width: 15%;
  min-width: 120px;
  font-size: 17px;
  padding: 11px 0;
  color: #908f91;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes void-animation-out {
  0%,
  to {
    opacity: 1;
  }
}
  .stripe-container .ResultTitle {
    color: #000;
    font-weight: 500;
    margin-bottom: 8px;
    font-size: 17px;
    text-align: center;
  }
  
  .stripe-container .ResultMessage {
    color: #1aa0ee;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 25px;
    line-height: 1.6em;
    text-align: center;
  }
  
  .stripe-container .ResetButton {
    border: 0;
    cursor: pointer;
    background: transparent;
  }
  


.stripe-container .FormRowInput {
  font-size: 17px;
  width: 100%;
  padding: 11px 15px 11px 0;
  color: #908f91;
  background-color: transparent;
  animation: 1ms void-animation-out;
  direction: ltr;
}

.stripe-container .FormRowInput::placeholder {
  color: #87bbfd;
}



.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

.stripe-container .SubmitButton {
  display: block;
  font-size: 17px;
  width: calc(100% - 30px);
  height: 40px;
  margin: 40px 15px 0;
  background-color: #F39422;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #f3ad57;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  will-change: transform, background-color, box-shadow;
}

.stripe-container .SubmitButton:active {
  background-color: #d782d9;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #e298d8;
  transform: scale(0.99);
}

.stripe-container .SubmitButton.SubmitButton--error {
  transform: translateY(15px);
}
.stripe-container .SubmitButton.SubmitButton--error:active {
  transform: scale(0.99) translateY(15px);
}

.stripe-container .SubmitButton:disabled {
  opacity: 0.5;
  cursor: default;
  background-color: #7795f8;
  box-shadow: none;
}

.stripe-container .ErrorMessage {
  color: red;
  position: absolute;
  padding: 0 15px;
  font-size: 13px;
  margin-top: 0px;
  width: 100%;
  transform: translateY(-15px);
  opacity: 0;
  animation: fade 150ms ease-out;
  animation-delay: 50ms;
  animation-fill-mode: forwards;
  will-change: opacity, transform;
}

.stripe-container .ErrorMessage svg {
  margin-right: 10px;
}

.stripe-container .Result {
  margin-top: 50px;
  text-align: center;
  animation: fade 200ms ease-out;
}

.stripe-container .ResultTitle {
  color: #fff;
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 17px;
  text-align: center;
}

.stripe-container .ResultMessage {
  color: #9cdbff;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 25px;
  line-height: 1.6em;
  text-align: center;
}

.stripe-container .ResetButton {
  border: 0;
  cursor: pointer;
  background: transparent;
}

.stripe-input:-webkit-autofill,
.stripe-input:-webkit-autofill:hover,
.stripe-input:-webkit-autofill:focus,

.stripe-input:-webkit-autofill:active , .stripe-input {
  background-color: #fff !important;
  background-clip: content-box !important;
  color: #606060 !important;
}

/* Change the white to any color */
.stripe-container input:-webkit-autofill,
.stripe-container input:-webkit-autofill:hover, 
.stripe-container input:-webkit-autofill:focus, 
.stripe-container input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px #7795f8 inset !important;
  -webkit-text-fill-color: white !important
}

 .FormRow > .stripe-input:disabled {
  /* color: #908f91 !important; */
  color: #606060 !important;

}
.CardNumberField-input-wrapper > .InputContainer > .InputElement{
  border-right: 1px solid;

}
.stripe-container .ccField{
  /* width: 100%; */
  margin: 0;
  border-radius: 0;
  box-shadow: none ;
  border-right: 1px solid #9cdbff;
}
.stripe-container .ccField:nth-child(3){
  /* width: 100%; */
  margin: 0;
  border-radius: 0;
  box-shadow: none ;
  border-right: none;
}
.ccField > .StripeElement{
 padding: 11px 5px 11px 5px;
}
.ccField:nth-child(1){
  width: 60%;
}
.ccField:nth-child(2){
  width: 20%;
}
.ccField:nth-child(3){
  width: 20%;
}
.fawry-btn{
  background-color: #f8cb00;
  border-radius: 50px;
  cursor: pointer;
  width: 233px;
  margin: 10px auto;
}
.fawry-btn:hover, .fawry-btn > img:hover {
  background-color: #dfba02;
}
.fawry-btn > img {
  width: 100px;
  background-color: transparent;
  border-radius: 50px;
  cursor: pointer;
  padding: 5px;
}
@media(max-width:992px){
  .stripe-container .FormRowLabel {
    min-width: 90px;
    font-size: 14px;
}
.stripe-container .FormRowInput {
  padding: 11px 0px 11px 0;
  direction: ltr;
  font-size: 15px;
  background-color: transparent !important;
}
.ElementsApp, .ElementsApp .InputElement {
  font-size: 15px;
}
.InputElement {
  font-size: 15px !important;
}
}
@media(max-width:400px){
  .ccField:nth-child(1){
    width: 55%;
  }
  .ccField:nth-child(2){
    width: 25%;
  }
}