/* Certificate */

.cert-container {
    width: 95%;
    margin: 0 auto;
    /* max-height: 100%;
    height: 100%; */
    background-image: url(/src/assets/images/certificate_background.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    margin: 4rem auto;
    border:#575757 1px solid
}
.section-one{
    display:block;
    padding: 5rem 0;
}
.section-one > .top-text {
    text-align: center;
    display: inline-block;
    width: 50%;
    line-height: 23px;
}
.top-text > p {
    font-size: 23px;
    color: #575757;
    margin-bottom: 0;
}
.top-text > span {
    font-size: 60px;
    margin-bottom: 0;
    color: #575757;
}
.section-one > .img-logo {
    display: inline-block;
    width: 25%;
}
.img-logo > img{
    width: 180px;
    margin: 0 auto !important;
    display: block;
}
.section-two{
    display: block;
    font-size: 23px;
    color: #575757;
    text-align: center;
    padding:5rem 0 0.5rem 0;
}
.section-three {
    display: block;
    font-size: 100px;
    text-align: center;
    color: #67bea6;
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 2px solid #d5d6d6;
    margin: 0 auto;
    width: 65%;
    line-height: 75px;
}
.section-four{
    display: block;
    font-size: 23px;
    color: #575757;
    text-align: center;
    padding:5rem 0 0.5rem 0;
}
.section-five{
    display: block;
    font-size: 23px;
    color: #575757;
    text-align: center;
}
.section-six{
    display: block;
    font-size: 23px;
    color: #575757;
    text-align: center;
    padding:7rem 0 0.5rem 0;
}
.section-seven {
    display: flex;
    padding-bottom: 6rem;
    padding-top: 6rem;
}
.section-seven > div {
    display: flex;
    width: 33.3%;
    justify-content: center;
    align-items: flex-end;
    color: #67bea6;
    font-size: 22px;
}
.section-seven > div > p {
    padding-right: 10px;
}
.signature{
    width: 110px;
}

/* courses certificate */
.my-certificate-table{
    width: 100%;
}
.my-cert-title{
    text-align: left;
}
.my-certificate-table > tbody > tr {
    border-bottom: 1px solid #c4c4c4;
    padding: 10px 0;
}
.my-certificate-table > tbody > tr:last-child{
    border-bottom: none;
}
.my-certificate-table > tbody > tr > td {
    /* width: 25%; */
    text-align: center;
    padding: 60px 0!important;
}
.table-cell {
    color: #a5a5a5;
}
.cert-table-btn {
    border-radius: 25px;
    color: #F39422;
    font-size: 26px;
    padding: 5px;
}
@media(max-width:992px){
    .my-cert-title {
        padding: 10px 15px 0 15px;
    }
    .my-certificate-table > tbody > tr > td {
        padding: 30px 2px!important;
        font-size: 20px;
    }


    .cert-container.cert-html-view .section-one > .top-text{
        display: block;
        text-align: center;
        width: 100%;
        margin-top: 50px;        
    }

    .cert-container.cert-html-view .section-one > .img-logo{
        margin: 0 auto;
        display: block;
        width: 45%;       
    }    


    .cert-container.cert-html-view .section-one {
        padding: 1rem 0;
    }

    .cert-container.cert-html-view .section-two {
        padding: 0rem 0 0.5rem;
    }

    .cert-container.cert-html-view .section-four {
        padding: 3rem 0 0.5rem;
    }

    .cert-container.cert-html-view .section-six {
        padding: 2rem 0 0.5rem;
    }

    .cert-container.cert-html-view .section-seven{
        align-items: flex-start;
        padding-bottom: 3rem;
        padding-top: 3rem;
    }

    .cert-container.cert-html-view .section-seven>div{
        width:auto;
        padding: 10px;
        display: flex;
        flex-direction: column;        
    }
    .cert-container.cert-html-view .section-three{
        font-size: 5rem;
    }
    
}