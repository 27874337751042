.about-page{
    background: rgb(243,245,244);
    background: linear-gradient(0deg, rgba(243,245,244,1) 0%, rgba(222,239,233,1) 100%);
}
.about-section-one {
    width: 100%;
    margin: 0 auto;
    /* background-image: url(/public/assets/images/background.svg); */
    /* background-size: cover; */
    padding-top: 30px;
    /* background-repeat: no-repeat; */
}

.inner-about-section-one { 
    width: 80%;
    margin: 0 auto;    
    /* height: 50rem; */
    padding-bottom: 100px;
}
.about-text-one{
    display: inline-block;
    width: 50%;
    margin-top: 100px;
}

.about-text-one > div {
    margin: 0 auto;
    width: 80%;
    text-align: justify;
    margin-top: 10px;
}
/* .about-text-one > div > h2 {
    color: #676767;
    text-align: left;
    font-size:35px  !important;
    font-weight: normal;
    margin-top: 0 !important;
    padding: 0px 20px 10px 20px;
} */
.about-text-one > div> p {
    color: #676767;
    text-align: left;
    width: 100%;
    line-height: 30px;
    font-size: 25px !important;
    margin: 0 auto;
}
.about-text-one > div > h2 {
    font-size: 40px;
    color: #676767;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 35px;
}
  .about-image-one {
    display: inline-block;
    width: 50%;
    margin-top: 100px ;
    float: right;
}
.about-image-one > img {
    border-radius: 40px;
    width: auto;
    margin:0 auto;
    display: block;
}
  /* Second section */
.gray-area{
    background-color:#fff ;
    border-radius:30% 0% 29% 46% / 100% 100% 100% 0% ;
    /* height: 38rem; */
}
.gray-area > .about-section-one {
    background-image: none;
}
.our-mission-image{
    display: inline-block;
    width: 50%;
    float: left;
}
.our-mission-image > img {
    border-radius: 40px;
    width: auto;
    margin: 70px auto;
    display: block;
}
.about-text-one >  div > .call-to-action-btn {
    display: block;
    margin: 25px 0px;
    padding: 10px 35px;
    text-align: center;
    /* text-transform: uppercase; */
    transition: 0.5s;
    font-size: 25px;
    background-size: 200% auto;
    color: white;
    border-radius: 50px;
    border: 0px;
    font-weight: normal;
    background-color: #F39422;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}
/* Read more + Read less */
  .about-text-one > div> p , details{
    /* display:inline; */
    color: #676767;
    text-align: justify;
    width: 100%;
    line-height: 30px;
    font-size: 25px !important;
    margin: 0 auto;

  }
  
  details {
    position:relative;
  }
  details summary {
    display:block;
    cursor: pointer;
    color: #F39422;
  }
  details summary:focus {
    outline:none;
  }
  details[open] {
    display:block;
    padding-bottom:25px;
    /* padding-top:10px; */
    animation: open .2s linear;
  }
  details[open] summary {
    position:absolute;
    bottom: 0;
    left:0;
  }
  
  details #open{padding-left:5px;text-align:middle;}
  details[open] #open{display:none;}
  details #close{display:none;}
  details[open] #close{display:block;}
  
  ::-webkit-details-marker {display: none;}
  
  @keyframes open {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
@media(max-width:1290px){
    .inner-about-section-one {
        width: 90%;
    }
    .about-image-one > img {
        max-width: 500px;
    }
}
@media(max-width:1060px){
   
    .about-text-one > div > h1 {
        font-size: 35px !important;
    }
    .about-image-one > img{
        max-width: 470px;
    }
}
@media only screen and (max-width: 992px) {
    .about-section-one{
        background-size: cover;
    }
    .about-text-one {
        display: block;
        width: 100%;
        float: none;
        margin-top: 0;
    }
    .about-text-one > h1 {
        font-size: 30px !important;
        padding: 0;
    }
    .about-section-one {
        background-image: none;
    }
    .about-text-one > div > h2 {
        font-size: 23px;
        color: #969696;
        text-align: center;
        margin-bottom: 15px;
    }
    .about-text-one > div > p {
        width: 100%;
        font-size: 19px !important;
        margin: 0 auto;
        color: #969696;
        padding: 0px;
    }
    .about-text-one > div > .call-to-action-btn {
        margin: 20px auto !important;
    }
    .about-image-one {
        display: block;
        width: 100%;
        padding: 0px;
        float: none;
        margin: 0 auto;
    }
    .about-image-one > img {
        max-width: 100%;
        margin:30px auto 0px auto;
    }
    .gray-area > .about-section-one > .inner-about-section-one > .about-image-one > img {
        max-width:55%;
        width: 250px;
        border-radius: unset;
    }
    .gray-area{
        padding-top: 10px;    
        border-radius: 150px 0 150px 0;
    }
    .gray-area > .intro-title {
        padding-top: 35px;
    }
    .gray-area > .about-section-one > .inner-about-section-one {
        flex-direction: column;
    }
    .inner-about-section-one{
        height: auto;
        display: flex;
        padding-bottom: 10px;
        flex-direction: column-reverse;
    }
    .about-text-one > div {
        margin: 0 auto;
        width: 100%;
        text-align: justify;
        margin-top: 10px;
    }
    details summary {
      text-align: center;
    }
    details[open] {
        color: #969696;
        text-align: center;
    }
    details[open] > summary > #close {
        text-align: center;
    }
    .about-page > div:nth-child(3) {
        padding-bottom: 0px;
    }
    .about-page > .about-section-one:first-child > .inner-about-section-one > .about-image-one > img {
        content: url("../../../public/assets/images/mob_about_ch.png");
        max-width: 100%;
        width: 350px;
    }
    .about-page > div:nth-child(3) >  .inner-about-section-one > .about-image-one > img{
        max-width: 100%;
        width: 110px;
    }
    .about-section-one {
        padding-top: 0px;
    }
}

@media(max-width:768px){
    .gray-area {
        padding-bottom: 0;
        border-radius:80px 0 80px 0px;
    }
    .about-text-one > div > p{
        width: 100%;
      }
      .about-text-one >  div > .call-to-action-btn {
        padding: 7px 35px;
        font-size: 22px;
    }
   
}



