:root{
    --card-bg-color:#b8efe0
}
.submitted-projects{
    margin-bottom: 150px;
}

.project-wrapper{
	/* padding:15px; */
	display:flex;
	flex-direction:row;
	justify-content:center;
	flex-wrap:wrap;
}
.noProjectMsg {
    text-align: center;
    margin-top: 15px;
}
.noProjectMsg > p {
    font-size: 18px;
}
.noProjectMsg > img {
    width: 350px;
    max-width: 100%;
}
.project-card{
    /* max-width:300px;
    margin:15px; */
    background:#fff;
    /* border:1px solid var(--card-bg-color); */
    text-align:center;
    cursor:pointer;
    position: relative;
}
/* .project-card:hover{
    box-shadow: 0 3px 10px 0 rgba(0,0,0,.1);
} */
.project-card:hover .card-img img{			
    opacity:0.8;
}

.card-img{
    position:relative;
    text-align:center;
    /* background:var(--card-bg-color); */
}
.card-img > div {
    padding: 0 !important;
}
.card-img img {
    max-height: 200px;
    width: 100%;
    object-fit: cover;
    height: 300px;
}
.project-item > .swiper > .swiper-button-prev , .project-item > .swiper > .swiper-button-next {
    height: 82% !important;
}
/* .card-img:before{
     content:''; 
    position: absolute;
    bottom: -8px;
    left: 50%;
    margin-left: -10px;
    width: 0;
    height: 0;
    border-top: solid 10px var(--card-bg-color);
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
} */
.card-img h1{
    position:absolute;
    margin:0;
    font-size:42px;
    bottom:15px;
    width:100%;
    color:#fff;
}
.card-details{
    width: 90%;
    font-size: 22px;
    color: #9c9b9b;
    margin: 10px auto;
}
.card-details span:first-child{		
        float: left;
}
.card-details span:last-child{		
    float: right;
}

.card-details span i{
    margin: 0 8px;
}
.card-text{
    padding:30px 15px;
    line-height:22px;
}
.read-more{
    display:inline-block;
    width:auto;
    text-align:center;
    text-transform:uppercase;
    background:var(--card-bg-color);
    color:#fff;
    padding:15px;
    margin-bottom:30px;
}
/* .read-more:hover{
    background: darken(var(--card-bg-color),20%);
} */
.project-container{
    width:90%;
    max-width: 500px;
    margin: 0 auto;
    padding: 0px !important;
}
.project-logo{
    position: absolute;
    z-index: 2;
    width: 160px;
    padding: 30px;
}
.project-media{
    width: 100%;
    border-radius: 55px 55px 0 0;
}
.project-details{
    display: flex;
    justify-content: space-around;
    flex-direction: row-reverse;
    margin: 0;
    padding: 10px 0 10px 0;
}
.project-details > p{
    margin: 0;
}
.download-project{
    color: #F39422;
}
.download-project:hover{
    color:  #e0881b;
}
@media(max-width:992px){
    .card-details span:first-child {
        font-size: 15px;
    }
    .project-media {
        border-radius: 25px 25px 0 0;
    }
    .project-logo {
        width: 120px;
    }
}