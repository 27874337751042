
.survey-wrapper{
    width: 100%;
    height: 100%;
    display: block;
}
.survey-container{
    height: 100%;
    background-color: #808080;
}
.survey-header{
    padding: 0 20px;
    background-color: #fff;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;

}
.mawaheb-logo {
    width: 130px !important;
    padding: 15px;
}
.survey-header-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* max-width: 700px; */
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    background-color: #fff;
}

.survey-header-item {
    margin: 0 auto;
    font-size: 22px;
    display: flex;
    color: #6dbba5;
    align-items: center;
    width: 33.3%;
    justify-content: flex-start
}
.survey-header-item > a{
    margin: 0 auto;
}
/* .survey-header-item:nth-child(1){
    justify-content: flex-start;
} */
.survey-header-item:nth-child(3){
    justify-content: flex-end;
}
.m-logo{
    justify-content: center;
}
.survey-header-item > img{
    width: 40px;
    padding: 0 5px;
}
.survey-progress-contriner{
    /* margin: 20px 0 0 0px; */
    background-color: rgb(228, 226, 226);
    height: 3px;
    border-radius: 20px;
    width: 95%;
    /* max-width: 700px; */
    margin: 0 auto;
}

.survey-progress-bar{
    background-color: #6dbba5;
    height: 100%;
    width:0px;
    transition: width 0.5s;
}

.survey-question-body {
    width: 100%;
    max-width: 100%;
    text-align: center;
    margin: 0 auto;
    height: 100%;
    background-color: #fff;
}
.survey-question-body > h1{
    font-size: 22px;
    color: #501d93;
    margin: 0;
    font-weight: bold;
    padding: 20px 0;
}
.survey-question-body > h4{
    color: #6dbba5;
    font-size: 20px;
}
.intro-image{
    margin: 30px auto;
    width: 90%;
    max-width: 300px;
}
.answers-container > .submit-button-auto{
    padding: 8px 30px 8px 30px;
}
.question-title {
    text-align: center;
    color: #501d93;
    padding: 22px 0 14px 0;
    margin: 0;
    width: 70%;
    max-width: 500px;
    margin: 0 auto;
    font-weight: bold;

}
.question-title2{
    font-size: 16px !important;
    padding: 8px 0 0 0;
}
.answers-container {
    display: flex;
    /* padding: 30px; */
    justify-content: space-evenly;
    align-items: stretch;
    /* width: 700px; */
    margin: 0 auto;
    flex-direction: column;
    padding: 0 30px;
}

.answer-item {
    padding: 10px;
    background-color: #f5f5f5;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    /* flex-direction: row-reverse; */
    justify-content: space-around;
    /* max-width: 15%; */
    height: 70px;
    width: 100%;
    margin: 8px 0;
    align-items: center;
}
.answer-item.selected{
    background-color: #c8c8c8;
}
.anwser-img {
    display: block;
    margin: 0 auto;
    width: 65px;
    margin-right:20px;
}
.anwser-text {
    font-size: 18px;
    text-align: left;
    font-weight: bold;
    padding: 10px ;
    width: 50%;
    direction: ltr;
}
.survey-header-item > div{
    cursor: pointer;
    /* margin-top: -10px; */
}
.survey-form-img{
    margin: 0 auto;
    display: block;
    width: 100%;
    max-width: 150px;
    padding-top: 50px;
}
.survey-field{
    padding: 7px 13px !important;
    width: 100% !important;
    max-width: 450px !important ;
    margin: 0 auto !important;
}
.survey-field > input {
    padding: 10px 20px !important;
    direction: ltr !important;
}
.survey-email::placeholder{
    text-align: left !important;
}

/* Survey Result design */
.surveyResult-container{
    margin: 0 auto;
    width: 95%;
    padding-bottom: 20px;
}
.dear-name{
    text-align: left;
    font-size: 25px;
}
.dear-name > span:nth-child(1){
    color: #000;
    padding: 0 2px;
}
.dear-name > span:nth-child(2){
    color: #6dbba5;
    padding: 0 2px;
}
.textOfResult{
    font-size: 20px;
    text-align: left;
    color: #808080;
    padding: 10px 0;
}
.progressCircles {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 25px;
    margin: 30px 0;
    padding: 25px 0 5px 0;
}
.resultText{
    color: #F39422;
    font-size: 19px;
    text-align: center;
    direction: ltr;
}
.circlesContainer{
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
}
.progressCircle{
    width: 20%;
}
.surveyReultBtn{
    background-color: #F39422;
    /* opacity: 90%; */
    color: #fff;
    border: none;
    outline: none;
    width: 350px !important;
    /* margin-top: 2rem; */
    display: block;
    cursor: pointer;
    margin: 4rem auto 4rem;
    padding: 4px 30px 4px 30px;
    font-size: 20px;
    border-radius: 30px;
}
.thePartsSection{
    display: flex;
    flex-direction: row;
}
.squaresContainer {
    width: 35%;
    background-color: #f4f4f4;
    border-radius: 20px;
    padding: 30px 10px;
}
.three-squares{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.square:nth-child(1){
    /* width: 40%; */
    width: 140px;
    background-color: #f4e1ca;
    color:#f49422 ;
    margin: 10px;
    border-radius: 15px;
    height: 140px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}
.square:nth-child(2){
    /* width: 40%; */
    width: 140px;
    margin: 10px;
    height: 140px;
    color: #1d699e;
    border-radius: 15px;
    background-color: #c9d8e2;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}
.square:nth-child(3){
    /* width: 40%; */
    margin: 10px;
    border-radius: 15px;
    height: 140px;
    width: 140px;
    background-color: #d3ccde;
    margin: 0 auto;
    color: #662584;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}
.chartContainer {
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 20px 30px;
}
.chartContainer > div:nth-child(2) > img {
    width: 100%;
}
.validityText{
    text-align: center;
}
.progressCircle > div > canvas {
    text-align: center;
}
.loaderIcon{
    width: 200px;
    margin: 0 auto;
    display: block;
}
@media(max-width:992px){

    .first-square{
        left:30px;
    }
    .second-square{
        right:34px;
    }

    .thePartsSection {
        display: flex;
        flex-direction: column;
    }
    .circlesContainer {
        flex-wrap: wrap;
    }
    .progressCircle {
        width: 33%;
        margin-top: 10px;
    }
    .progressCircles {
        padding: 25px 0 20px 0;
    }
    .progressCircle > div:nth-child(2){
        font-size: 16px;
    }
    .squaresContainer {
        width: 70%;
        margin: 0 auto;
    }
    .chartContainer{
        width: 100%;
        padding-top: 30px;
    }
    .square:nth-child(2) , .square:nth-child(1){
        /* width: 30%; */
        width: 120px;
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .square:nth-child(3){
        /* width: 32%; */
        width: 120px;
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .resultText {
        color: #F39422;
        font-size: 18px;
        padding-top: 10px;
    }
    .validityText{
        font-size: 20px;
    }
    .textOfResult {
        font-size: 16px;
    }
}

@media(max-width:768px){
    .survey-question-body > button {
        margin: 3.5rem auto 0rem;
        padding: 8px 30px 8px 30px;
    }
    .squaresContainer {
        width: 70%;
        margin: 0 auto;
    }
    .three-squares {
        margin-top: 20px;        
        margin: 0 auto;
        width: 90%;
    }
}

@media(max-width:700px){
    .mawaheb-logo {
        width: 130px;
    }
    .survey-question-body{
        width: 100%;
    }
    .survey-question-body > h1 {
        font-size: 18px;
        color: #501d93;
        font-weight: bold;
        width: 90%;
        max-width: 300px;
        margin: 0 auto;
        line-height: 28px;
        padding-top: 35px;
    }
   
    .intro-image {
        width: 90%;
        max-width: 180px;
    }
    .survey-question-body > h4 {
        padding: 0;
    }
    .survey-question-body > button{
        margin: 3.5rem auto 0rem;
        padding: 8px 30px 8px 30px;
    }
    .answers-container {
        flex-direction: column;
        width: 90%;
        padding: 10px 0;
    }
   
    .answer-item {
        width: 100%;
        max-width: 100%;
        height: 60px;
        margin: 4px 0;
    }
    .question-title {
        font-size: 20px;
    }
    .anwser-text {
        font-size: 13px !important;
        width: 70%;
    }
    .survey-email::placeholder{
        font-size: 16px;
     }
     .survey-field > input {
        padding:10px 20px !important;
    }
    .intro-image {
        margin: 55px auto;
    }
    .survey-header{
        padding: 0;
    }
    .survey-header-item > img {
        width: 30px;
    }
    .survey-header-item {
        font-size: 15px;
    }
    .intro-int > form > .submit-survey{
        margin: 2rem auto 0rem;
        padding: 8px 30px 8px 30px;
    }
   .surveyReultBtn{
    max-width: 90%;
   }
   .surveyReultBtn {
    font-size: 17px;
    margin: 2rem auto 1rem;
}
.three-squares {
    width: 100%;
}
}
@media(max-width:600px){
    .squaresContainer {
    width: 90%;
    margin: 0 auto;
}
.square:nth-child(1), .square:nth-child(2), .square:nth-child(3){
    font-size: 14px;
}
.chartContainer > div:nth-child(2) > img {
    width: 100%;
}
}
@media(max-width:468px){
    .square:nth-child(1), .square:nth-child(2) {
        margin: 10px 5px;
        width: 120px;
        height: 120px;
    }
    .square:nth-child(3)  {
        width: 120px;
        height: 120px;
    }
}
@media(max-width:400px){
    .surveyReultBtn {
        width: 290px !important;
        max-width: 90%;
        font-size: 14px;
        margin: 1rem auto;
    }
    /* .square:nth-child(2), .square:nth-child(1) {
        width: 40%;
    }
    .square:nth-child(3) {
        width: 40%;
    } */
    .square:nth-child(2), .square:nth-child(1) , .square:nth-child(3){
        width:100px;
        height: 100px;
        font-size: 13px;
    }
    
    .chartContainer > div > img{
        width: 100%;
    }
    .progressCircle {
        width: 33%;
        margin-top: 30px;
    }
    .progressCircles {
        margin: 30px 0 10px 0;
    }
    .resultText {
        font-size: 15px;
    }
    .validityText {
        font-size: 15px;
    }
}



.survey-left-image{
    display: block;
    width: 50% !important;
    margin: 0 auto;
}

.thePartsSection .resultText{
    margin-bottom: 30px;
}
.square, .square-inside{
    position: relative !important;
    background: none !important;
}
.square img{
    position: absolute;
    width:100%;
}

.first-square{
    color: #1d699e !important;
    margin:3px !important;
    font-size: 1.2rem !important;
    width: 100px !important;
}
.second-square{
    color: #f49422 !important;
    margin:3px !important;
    font-size: 1.2rem !important;
    width: 100px !important;
} 
.third-square{
    color: #a98ee1 !important;
    margin:3px !important;
    font-size: 1.2rem !important;
    width: 100px !important;
    top: -55px;
}

.modal-lg-custom{
    width:80% !important;
}

@media (max-width: 900px) {
    .modal-lg-custom{
        width:100% !important;
    }

    .third-square{
        top:0px !important;
    }

    .square{
        font-size: 0.8rem !important;
    }

    .square:nth-child(1), .square:nth-child(2) {
        right: 5px;
        font-size: 11px !important;
        left : 0px !important;
    }
    .square:nth-child(3)  {
        font-size: 11px !important;
    }
        
  }