body {
    background-color:#ececec;
}
#page-wrapper{
    background-color:#ececec;
}

.title-box { grid-area: title-box; }
.desc-box { grid-area: desc-box; }
.video-box { grid-area: video-box; }
.obj-box { grid-area: obj-box; }
.ext-box { grid-area: ext-box; }
.slider-box { grid-area: slider-box; }
.about-expert { grid-area: expert-box; }
.start-course-btn {grid-area: btn-box; padding: 0 80px;}
.submitted-projects > .title-box {grid-area: projects_title;}
.project-wrapper {grid-area: project-wrapper;}
.white-box {
    margin: 0 auto;
    display: grid;
    background-color: #fff;
    grid-gap: 20px;
    grid-auto-flow: column;
    grid-column-gap: 10px;
    grid-auto-columns: 1fr;  
    width:100%;
    grid-template-areas:
    'video-box video-box video-box title-box title-box title-box'
    'video-box video-box video-box btn-box btn-box btn-box'
    'desc-box desc-box desc-box ext-box ext-box ext-box'
    'desc-box desc-box desc-box obj-box obj-box obj-box'
    'slider-box slider-box slider-box slider-box slider-box slider-box'
    'expert-box expert-box expert-box expert-box expert-box expert-box';
    padding: 30px 0px 30px 0px;
    margin-top: 0;
    margin-bottom: 20px;
    border-radius: 0;
    box-shadow:none;
}

.white-box2 > .submitted-projects{
    width: 95%;
    height: auto;
    overflow-x: auto;
    margin: 0 auto;
    display: grid;
    grid:
    'projects_title  projects_title  projects_title  projects_title  projects_title  projects_title'
    'project-wrapper project-wrapper project-wrapper project-wrapper project-wrapper project-wrapper'
    'project-wrapper project-wrapper project-wrapper project-wrapper project-wrapper project-wrapper';

    grid-gap: 20px;
    grid-auto-flow: column;
    grid-column-gap: 10px;
    grid-auto-columns: 1fr;  
    background-color: #fff;
    padding:10px 10px 30px 10px;
    margin-top: 70px;
    margin-bottom: 20px;
    border-radius: 55px;
    margin-bottom: 8rem;
    box-shadow: rgb(14 30 37 / 12%) 0px 2px 4px 0px, rgb(14 30 37 / 32%) 0px 2px 16px 0px;
}

.title-box {
    grid-area: title-box;
    width: 90%;
    margin: 50px auto auto auto;
    color: #501d93;
    font-size: 25px;
}
.slider-box{
width: 100%;
}
.about-expert{
width:95%;
margin: 0 auto;
display:flex;
padding: 40px 10px ;
}
.about-expert-text{
display: inline-block;
width: 60%;
}
.about-expert-text > p {
display: block;
}
.about-expert-img{
display: inline-block;
width: 40%;
}
.about-expert-img > img {
    margin: 0 auto;
    display: block;
    width: 100%;
    max-width: 210px;
}
.desc-box > .read-more-btn {
    font-size: 20px !important;
    padding-bottom: 10px;
}
.iframe-video {
    max-width: 98%;
    margin: 0 auto;
    display: block;
    height: 100%;
    max-height: 266px;
    width: 75%;
    border-radius: 25px;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}
.video-js {
    width: 90% !important;
    height: 100% !important;
}
.img-thumb{
    border-radius: 25px;
    position: relative;
    cursor: pointer;
    /* height: 21rem !important; */
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.playIcon3 {
    width: 90px !important;
    max-width: 40%;
    display: block !important;
    position: absolute;
    cursor: pointer;
    top: 29% !important;
    left: 36%;
}

.playIcon {
    width: 90px !important;
    max-width: 30%;
    display: block !important;
    margin: 0 auto;
    position: absolute;
    top: 5%;
    left: 36%;
    cursor: pointer;
}
/* .playIcon2 {
    top: calc(50% - 50px) !important;
} */
.playIconProject{
    width: 90px !important;
    max-width: 30%;
    display: block !important;
    position: absolute;
    cursor: pointer;
    left: 36%;
    top: 29%;
}

.item-title {
    line-height: 50px;
    border-radius: 0 0 25px 25px;
    opacity: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0px;
    border-radius:0 0 22px 22px;
    background-color: #fff;
    height: 50px;
    cursor: pointer;
    z-index: 5;
    margin: 0;
    text-align: center;
    font-size: 23px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: .5s ease;

}
.slider-items .course-item{
    height: auto !important;
    box-shadow: none;
    position: relative;
}
.thumbnail-container > .video-poster{
    height: 100%;
    object-fit: cover;
    /* max-height: 300px; */
}
.slider-box > .videos-carousel-container > .swiper > .swiper-wrapper > .swiper-slide {
  height: auto !important;
}
.slider-box > .videos-carousel-container > .swiper > .swiper-wrapper > .swiper-slide > .swiper-item > .thumbnail-container {
    height: 100%;
}
/* .img-thumb:hover  .item-title{
    display: block !important;
} */
.box-title {
    width: 80%;
    text-align: left;
    margin: 5px auto;
    color: #676767;
    font-size: 25px;
}
.desc-box > .box-title {
    font-size: 25px;
}
.box-text {
    width: 80%;
    margin: 8px auto 20px auto;
    font-size: 1.6em;
    color: #9c9b9b;
}

.start-course-btn > button {    
    width: 200px;
    margin: 18px 10px;
    padding: 15px 30px;
    text-align: center;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 50px;
    font-size: 23px;
    /* display: block; */
    display: inline-block;
    border: 0px;
    font-weight: normal;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #ffa034 0%, #ff8800  51%, #f08f10  100%);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}
/* .start-course-btn > button:first-child{
    margin-left: 80px;
}
.start-course-btn > button:nth-child(2){
    margin-left: 80px;
} */
   .list-text > li {
    list-style-type: decimal;
    display: list-item;
    margin-left: 30px;

    }
    .videoPlayIcon {
        width: 120px !important;
        max-width: 30%;
        display: block !important;
        margin: 0 auto;
        position: absolute;
        top: 17.3%;
        left: 68.5%;
        cursor: pointer;
    }
    video{
        border-radius: 20px;
        width: 90%;
        display: block;
        margin: 0 auto;
    }
    .video-box > div > .mediaplugin > div {
        margin: 0 auto;
    }
    .item-project{
        height: 18rem !important;
        width: 100% !important;
    }
    .project-item {
        width: 100% !important;
        margin: 0 auto;
    }
    .project-item > .slider-items > .owl-nav > .owl-next {
        border-radius: 0;
        background-image: linear-gradient(to right, rgba(255,0,0,0), #fd8b02) !important;
        background-color: transparent !important;
        width: 160px !important;
        height: 81.5% !important;
        opacity: 45% !important;
    }
   
    .videos-carousel-container{
        display: block;
        height: 100%;
        width: 100%;
        margin-top: 4%;
        padding: 50px 0 15px 0;
    }
    .course-swiper .swiper-button-next{
        border-radius: 0;
        background-image: linear-gradient(to right, rgba(255,0,0,0), #fd8b02) !important;
        background-color: transparent !important;
        width: 15% !important;
        height: 100% !important;
        opacity: 45% !important;
    }
    .course-swiper .swiper-button-prev {
        border-radius: 0;
        background-image: linear-gradient(to left, rgba(255,0,0,0), #fd8b02) !important;
        background-color: transparent !important;
        width:15% !important;
        height: 100% !important;
        opacity: 45% !important;

    }
    .course-swiper .swiper-button-next,.course-swiper .swiper-button-prev {
        position: absolute;
        top: 0 !important;
        z-index: 10;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0 !important;
    }
    .course-swiper .swiper-button-next{
        right:0 !important;
    }
    .course-swiper .swiper-button-prev {
        left:0 !important;
        top: -5px !important;
    }
    .swiper-button-prev:after {
        display: none !important;
    }
    .swiper-button-next:after {
        display: none !important;
    }
 /****** Design by Shahed ******/
    .white-box .title-box {
        align-items: flex-end;
        color: #4d2b85;
        display: flex;
        font-size: 2.6em;
        font-weight: 900;
        height: 100%;
        justify-content: center;
        line-height: normal;
        margin: auto;
        text-align: center;
        text-shadow: 0 4px 4px #00000040, 0 4px 4px #00000040, 0 4px 4px #00000040;
        width: 100%;
    }
    .start-course-btn {
        margin: 0 auto;
    }
    .desc-box {
        margin: 0;
    }
    .box-title {
        color: #4d2b85;
        font-weight: 700;
    }
    .box-text, .box-title {
        width: 76%;
    }
    .box-text {
        color: #000;
        font-size: 1.3em;
        font-weight: 400;
        text-align: justify;
    }
    .slider-box {
        background-color: #d9d9d9;
    }
    .slider-box .box-title {
        margin: 0 auto;
        padding: 2.6rem 0 0;
    }
    .desc-box {
        margin: 0;
    }
    .desc-box .read-more-btn {
        width: 76%;
    }
    .slider-box .videos-carousel-container .slider-items .swiper-button-next,.slider-box .videos-carousel-container .slider-items .swiper-button-prev{
        background-image: none !important;
        opacity: 100% !important;
    }
    .slider-box .swiper-button-prev.swiper-button-disabled,.slider-box .swiper-button-next.swiper-button-disabled {
        opacity: 50% !important;
        cursor: auto;
        pointer-events: none;
    }
    .slider-box .swiper > .courses-swiper {
        width: 95% !important;
        margin: 0 auto;
    }
    .slider-box .swiper > .swiper-wrapper > .slider-items > .swiper-wrapper > .swiper-slide {
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px;
        background: transparent;
        transition: all .3s ease;
    }
    .slider-box .swiper > .swiper-wrapper > .slider-items{
        width: 90% !important;
        position: static!important;
    }
    .slider-box .swiper > .swiper-wrapper > .slider-items{
        position: unset !important;
    }
    .bk-slider-course{
        position: relative;
        width: 100%;
        height: auto;
        padding: 0px;
    }
    .bk-slider-course > .swiper-course{
        padding-bottom: 0px !important;
    }
    .bk-slider-course > .swiper-course > .course-swiper {
        width: 88% !important;
        margin: 0 auto;
    }
    .bk-slider-course > .swiper-course > .swiper-wrapper > .slider-items > .swiper-wrapper > .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px;
        background: transparent;
        transition: all .3s ease;
    }
    .bk-slider-course > .swiper-course > .swiper-wrapper > .slider-items {
        width: 92% !important;
        position: unset !important;
    }
    .videos-carousel-container .course-swiper .slider-items .swiper-button-next,.videos-carousel-container .course-swiper .slider-items .swiper-button-prev{
        position: absolute;
        width: 18px !important;
    }
    .videos-carousel-container .course-swiper .slider-items .swiper-button-next{
        right: 12px !important;
    }
    .videos-carousel-container .course-swiper .slider-items .swiper-button-prev{
        left: 8px !important;
    }
    .bk-slider-course .swiper-item .thumbnail-container {
        height: 100%;
        max-height: 195px;
    }
    .white-box2 > .submitted-projects{
        background-color: transparent;
        box-shadow:none;
        margin-top: 1rem;
    }
    .submitted-projects .title-box{
        color: #4D2B85;
        font-weight: 700;
        width: 94%;
        margin: 0 auto 1rem auto;
    }
    .submitted-projects .project-wrapper{
        border-radius: 15px;
        background: #FFF;
        width: 94%;
        margin: auto auto auto auto;
    }
    .submitted-projects .project-item{
        padding: 4rem 0.5rem;
    }
    .submitted-projects .project-item .noProjectMsg p{
        color: #6A6A6A;
        text-align: center;
        font-size: 18px;
        font-weight: 300;
        line-height: normal;
    }
    .courseDesc {
        display: block;
    }
    .relatedCourses{
        margin:  auto auto 4rem auto;
    }
    .relatedCourses .title-box{
        color: #4D2B85;
        font-weight: 700;
        width: 88%;
        margin: 3rem auto 2.8rem auto;
    }
    .relatedCourses .courseCard {
        max-width: 300px;
        margin: 0 25px;
    }
    .bk-slider-course .swiper-item .thumbnail-container {
        height: 100%;
        max-height: 195px;
    }
    .user-enrollrd-container{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .user-enrollrd-container > button {
        width: 200px;
        margin: 10px 10px;
        padding: 15px 0px;
        text-align: center;
        transition: 0.5s;
        background-size: 200% auto;
        color: white;
        border-radius: 50px;
        font-size:1.4em;
        display: inline-block;
        border: 0px;
        font-weight: normal;
        background-image: linear-gradient(45deg, #6FBBA4 0%, #6FBBA4 51%, #6FBBA4 100%);
        cursor: pointer;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
    }
    .start-course-btn > button {
        margin: 18px auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .bmpui-ui-watermark[aria-label="Link to Homepage"]{
        display: none !important;
    }
    @media screen and (max-width: 992px) {
        .white-box {
            display: block;
            border-radius: 0;
            margin-top: 30px;
            margin: auto auto auto auto;
            padding: 10px 0px 0px 0px;
        }
        .start-course-btn{
            padding: 0;
        }
        video {
            border-radius: 20px;
            width: 100%;
        }
        .white-box2{
            margin-bottom: 0;
        }
        .title-box {
            grid-area: title-box;
            width: 95%;
            text-align: left;
            margin-bottom: 1rem;
            margin-top: 0;
            /* color: #b6b6b6; */
            margin-top: 1rem;
            font-size: 22px;
            text-align: justify;
        }
        .mediaplugin_videojs > div{
            max-width: unset !important;
        }
        .box-text {
            width: 90%;
            margin: 12px auto 20px auto;
            font-size: 1.3em;
            color: #9c9b9b;
        }
        .video-box{
            margin-bottom: 25px;
            margin-left: 25px;
            margin-right: 25px;            
        }
        .desc-box>.box-title, .ext-box>.box-title, .obj-box>.box-title {
            color: #4d2b85;
            font-size: 2.2em;
            margin: 5px auto;
            text-align: left;
            width: 90%;
        }
        .start-course-btn > button {
            margin: 30px auto;
            display: block;
        }
        .videos-carousel-container > .slider-items > .owl-nav > .owl-prev {
            width: 15% !important;
        }
        .videos-carousel-container > .slider-items > .owl-nav > .owl-next {
            width: 15% !important;
        }
        .videos-carousel-container {
            padding: 10px 0;
        }
        .slider-box > .box-title {
            color: #9c9b9b;
            font-size: 25px;
        }
        .about-expert-img > img {
            width: 160px;
            max-width: 100%;
        }
        .about-expert-text > .box-title {
            width: 90%;
            color: #9c9b9b;
            font-size: 25px;
        }
        .white-box2 > .submitted-projects {
            border-radius: 25px;
            margin-top: 0;
            margin-bottom: 4rem !important;
        }
        .start-course-btn > button,.user-enrollrd-container > button{
            padding: 7px 0px;
            font-size:1.4em;
        }
        .user-enrollrd-container > button{
            margin: 0 10px;
        }
        .about-expert {
            padding: 20px 10px;
        }
        /* .start-course-btn > button:first-child{
            margin-left: 0 !important;
        }
        .start-course-btn > button:nth-child(2){
            margin-left: 0 !important;
        } */
        .relatedCourses .courseCard {
            margin-bottom: 2.5rem;
        }
        .desc-box .read-more-btn {
            width: 90%;
        }
        .slider-box {
            padding: 0 0 30px 0;
        }
        .videos-carousel-container .course-swiper .slider-items .swiper-button-next {
            right: -10px !important;
        }
        .videos-carousel-container .course-swiper .slider-items .swiper-button-prev {
            left: -7px !important;
        }
        .video-box .landing-video{
            margin: auto;
            width: 90%;
        }
        #page-wrapper{
            background-color: #fff;
        }
        .coursesDeatils ul {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin: 3rem 0 30px 0;
        }
        .tab-content{
            padding: 0 2rem;
        }
        .tab-cources{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 80%;
            margin: 0 auto;
        }
        .tab-content .coursesCard{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            width: 65%;
            margin: 0 auto 2.5rem auto;
        }
        .tab-content .coursesCard .courseDesc{
            max-width: 220px;
            width: 100%;
        }
        .tab-content .coursesCard > div:not(.coursesCardImg) {
            width: 100%;
            display: flex;
            margin: 0 auto;
            padding: 0 0 0 0.85rem;
            flex-direction: column;
            justify-content: space-between;
            gap: 4px;
        }
        .tab-content .coursesCard .coursesCardImg{
            max-width: 200px;
            width: 90%;
            display: block;
            margin: 0 auto;
        }
        .white-box .title-box{
            margin: auto auto 1rem auto;
        }
        .tab-content .coursesCard .coursesCardImg img{
            max-width: 100%;
            display: block;
            margin: 0 auto;
            height: 100%;
        }
        .box-text ul.list-text{
            flex-direction: column;
        }
        .tab-content .coursesCard .courseDesc{
            max-width: 180px;
            width: 100%;
        }
        .tab-content .tab-outline .coursesCard .coursesCardImg img {
            border-radius: 15px;
        }
        .list-text > li {
            margin-left: 20px;
        }
        .user-enrollrd-container {
            width: 90%;
            margin: 0 auto;
        }
        .tab-outline .coursesCard .courseDesc,.tab-relatedCources .coursesCard .courseDesc{
            overflow: inherit;
            white-space: wrap;
            max-width: 180px;
        }
        .tab-relatedCources .coursesCard .courseDesc{
            max-width: 96%;
        }
        .tab-outline .coursesCard .coursesCardImg img{
            aspect-ratio: auto 100/53 !important; 
            height: 100%;
            width: 200px;
            max-height: 109px; 
            min-height: 109px ;
        }
        .tab-relatedCources .coursesCard .coursesCardImg img{
            aspect-ratio: auto 50/31 !important; 
            height: 100%;
            width: 200px;
            max-height: 124px; 
            min-height: 124px ;
        }
        .white-box .title-box {
            font-size: 2.5em;
        }
    }
    @media(max-width:830px){
        .tab-content .coursesCard{
            width: 85%;
        }
    }
    @media(max-width:768px){
        .item-title-slider {
            padding: 4px 0;
            font-size: 18px;
        }
        .tab-content .coursesCard,.tab-content .coursesCard .coursesCardImg{
            width: 100%;
        }
        .white-box .title-box {
            text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 3px 4px rgba(0, 0, 0, 0.1) !important;
            font-weight: 800;
        }
        .tab-cources {
            width: 65%;
        }
        .desc-box>.box-title, .ext-box>.box-title, .obj-box>.box-title {
            font-size: 1.8em;
        }
        .coursesDeatils ul .categoryList {
            font-size: 1.4rem;
        }
        .tab-content {
            padding: 0 1.1rem;
        }
        .white-box .title-box {
            font-size: 2.35em;
        }
    }
    @media(max-width:600px){
        .card-details span:first-child {
            font-size: 15px;
            width: 100%;
        }
        .card-details span:last-child {
            float: none;
            width: 100%;
        }
        .tab-content .tab-outline .coursesCard .courseDesc{
            text-align: center;
        }
        .tab-cources {
            width: 90%;
        }
    }
    @media(max-width:500px){
        .courseName {
            font-size: 1.1em;
        }
        .tab-content .coursesCard .courseDesc {
            width: 90%;
        }
        .coursesDeatils ul .categoryList {
            margin: 0 10px;
            font-size: 1.25rem;
        }
        .tab-cources{
            width: 100%;
        }
        .desc-box>.box-title, .ext-box>.box-title, .obj-box>.box-title {
            font-size: 1.6em;
        }
        .box-text {
            font-size: 1.2em;
        }
        .user-enrollrd-container > button {
            margin: 0 6px;
        }
        .start-course-btn > button, .user-enrollrd-container > button {
            font-size: 1.2em;
        }
        .tab-outline .coursesCard .coursesCardImg img{
            width: 170px;
            max-height: 110px; 
            min-height: 110px ;
        }
        .tab-outline .coursesCard .coursesCardImg,.tab-relatedCources .coursesCard .coursesCardImg{
            max-width: inherit;
        } 
        .tab-relatedCources .coursesCard .coursesCardImg img{
            width: 180px;
            max-height: 120px; 
            min-height: 120px ;
        }
        .tab-content .coursesCard .courseDesc,.tab-content .coursesCard .courseMore{
            font-size: 1em;
        }
        .tab-relatedCources .coursesCard .courseDesc{
            word-break: break-word !important;
        }
        .white-box .title-box {
            font-size: 2.2em;
        }
        .tab-content .coursesCard > div:not(.coursesCardImg) {
            padding: 0 0 0 0.1rem;
            gap:2px;
        }
        .tab-content:not(.tab-outline) .coursesCard .courseDesc{
            line-height: 1.2em;
        }
    }
    @media(max-width:400px){
        .tab-content .coursesCard > div:not(.coursesCardImg) {
            gap: 0px;
        }
        .tab-content .coursesCard .courseName {
            font-size: 1.1em;
        }
        .tab-content .coursesCard .courseDesc,.tab-content .coursesCard .courseMore{
            font-size: 0.9em;
        }
        .tab-outline .coursesCard .courseDesc{
            font-size: 1.1em;
        }
        .tab-content .coursesCard {
            gap: 0;
            width: 100%;
            margin: 0 auto 2rem auto;
        }
        .start-course-btn > button,.user-enrollrd-container > button{
            font-size: 1em;
        }
        .start-course-btn > button {
            margin: 30px auto 15px auto;
        }        
        .coursesCard .coursesCardImg{
            flex-basis: 70% !important;
        }
        .tab-cources .coursesCard > div:not(.coursesCardImg) {
            flex-basis: 70% !important;
        }
        .coursesDeatils ul {
            margin: 2.5rem 0 30px 0;
        }
        .tab-content .coursesCard .courseDesc {
            max-width: 150px;
            width: 100%;
            display: block;
        }
        .start-course-btn > button,.user-enrollrd-container > button {
            font-size: 1em;
        }
        .tab-content:not(.tab-outline) .coursesCard .courseDesc{
            line-height: 1.25em;
        }
        .tab-relatedCources .coursesCard .coursesCardImg img {
            width: 160px;
            max-height: 114px;
            min-height: 114px;
        }
        .tab-outline .coursesCard .courseDesc,.tab-relatedCources .coursesCard .courseDesc{
            max-width: 150px;
        }
        .coursesDeatils ul .categoryList {
            font-size: 1.1rem;
        }
        .desc-box>.box-title, .ext-box>.box-title, .obj-box>.box-title {
            font-size: 1.3em;
        }
    }
    @media(max-width:350px){
        .tab-content {
            padding: 0 1rem !important;
        }
        .coursesDeatils ul .categoryList {
            margin: 0 6px;
            font-size: 1rem;
        }
        .coursesCard .coursesCardImg{
            flex-basis: 80% !important;
        }
        .tab-cources .coursesCard > div:not(.coursesCardImg) {
            flex-basis: 80% !important;
        }
        .tab-content .coursesCard > div:not(.coursesCardImg) {
            gap: 0px;
        }
        .tab-content .coursesCard {
            margin: 0 auto 1.5rem auto;
        }
        .tab-content .coursesCard .courseName {
            font-size: 1em;
        }
        .tab-content .coursesCard .courseDesc, .tab-content .coursesCard .courseMore{
            font-size: 0.85em;
        }
        .tab-content .coursesCard .coursesCardImg {
            max-width: 150px;
            width: 90%;
        }
        .tab-content .tab-outline .coursesCard .courseDesc {
            max-width: 130px;
        }
        .user-enrollrd-container > button {
            padding: 7px 3px;
            font-size: 1.5em;
            margin: 0 3px;
        }
        .tab-content .coursesCard .courseDesc{
            font-size: 1.15em;
        }
        .tab-outline .coursesCard > div:not(.coursesCardImg) {
            padding: 0 !important;
        }
        .tab-content .coursesCard > div:not(.coursesCardImg) {
            flex-basis: 45% !important;
        }
        .tab-content .coursesCard .coursesCardImg img {
            max-height: 90px;
            min-height: 90px;
        }
        .tab-relatedCources .coursesCard .courseDesc{
            max-width: 130px;
        }
        .tab-content:not(.tab-outline) .coursesCard .courseDesc{
            line-height: 1em;
        }
    }