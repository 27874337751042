.contact-us-intro{
    display: block;
    width: 100%;
    height: 400px;
    background-color: #fff;
    background-image: url(/public/assets/images/parts_contact_us.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.contact-us-text {
    display: block;
    width: 100%;
    max-width: 460px;
    margin: 0 auto 0 13%;
    padding-top: 100px;
    color: #676767;
}
.contact-us-text > h1 {
    font-size: 45px;
}
.contact-us-text > p{
    font-size: 25px;
}
.contact-us-container{
    background-color: #e9e9e9;
}
.contact-us-lower-container {
    width: 85%;
    padding: 50px 0 0 0;
    margin: 0px auto;
    /* background-color: #e9e9e9; */
}
.white-area{
    overflow: hidden;
    font-size: 1.2em;
    margin-bottom: 10rem;
    background-color: #fff;
    padding: 55px 20px 55px 20px;
    border-radius: 50px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
   
.contact-us-form{
        display: block;
        width: 70%;
        margin: 0 auto;
}
/* .contact-us-field{
        display: block;
        width: 100%;
        margin: 20px auto auto auto;
        float: left;
        clear: both;
        padding: 10px 30px;
        border-radius: 25px;
        outline: none;
        border: 1px #c9c9c8 solid;
    }

.contact-us-field::placeholder{
color: #c9c9c8 ;
font-size: 15px;
font-family: "STC";
} */
.contact-field {
    margin-bottom: 3rem
}
.contact-us-btn {
    border-radius: 30px;
    background-color: #F39422;
    color: #fff;
    padding: 8px;
    font-size: 22px;
    width: 150px;
    float: right;
    margin-top: 20px;
}
textarea{
    height: 200px;
}
@media(max-width:992px){
    
    .contact-us-intro{
        background-size: 400px;
        background-image: url(/public/assets/images/mob_contactus.png);

    }
    .contact-us-lower-container {
        width: 90%;
        padding: 30px 0;
    }
    .contact-us-form {
        display: block;
        width: 100%;
        margin: 0 auto;
    }
    .contact-us-lower-container > .white-area {
        border-radius: 25px;
        margin-bottom: 30px;
    }
    .contact-us-text {
        width:90%;
        text-align: center;
        font-size: 23px;
        padding-top: 0;
        padding-bottom: 20px;
        margin: 0 auto;
    }
    .contact-us-text > h1 {
        font-size: 23px;
        color: #969696;
    }
    .contact-us-text > p {
        font-size: 19px;
        color: #969696;
        margin: 0 auto;
        padding-top: 10px;
        width: 100% !important;
    }
    .contact-field {
        margin-bottom: 2rem;
    }
    .contact-us-container {
        padding-bottom: 0;
    }
    .contact-field label {
        top: 7px;
    }
    .contact-field input {
        padding: 6px 42px !important;
    }
    .contact-us-intro {
        height: 400px;
        display: flex;
        background-position: top;
        flex-direction: column-reverse;
    }
.contact-us-btn {
    width: 90%;
    max-width: 250px;
    float: none;
    display: block;
    margin: 0 auto;
}
}
@media(max-width:400px)
{
    .contact-us-intro {
        background-size: 300px;
    }
    .contact-us-intro {
        height: 363px;
    }
}
@media(max-width:300px){
    .contact-us-text{
        transform: scale(0.8)
    }
}
