.chatBot-icon{
    display: flex;
}
.chatBot-icon span{
    color: #F39422;
    font-size: 17px;
    margin: 0px 5px;
    font-weight: 700;
    line-height: 21px;
    margin-top: -2px;
}
.IuANS, .gCiKwx, .gWiOKS, .huReFX, .kidIpT, .qODWE, .rsc>a, .vITkw {
    height: 40px;
    width: 90px;
    border: 1px solid #F39422;
    border-radius: 25px;
    background-color: #fff;
}
.sc-gqjmRU .hhLalC .rsc-header{
 background-color: #F39422 !important;
}
@media(max-width:992px){
    .IuANS, .gCiKwx, .gWiOKS, .huReFX, .kidIpT, .qODWE, .rsc>a, .vITkw{
        bottom: 92px !important;
    }
}