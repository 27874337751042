.on-off-wrapper{
    display: flex;
    align-items: center;
    margin-top:15px;
    font-size: 1.5rem;
}
.on-off-wrapper > div{
    margin-right: 15px;
}
.on-off-toggle{
    width : 120px;
    background: #e5e4e4;
    position: relative;
    height: 40px;
    border-radius: 10px;    
    overflow: hidden;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}

.on-off-toggle input[type=checkbox]{
    display: none;
}
.on-off-toggle label{
    position: relative;
    width:100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.on-off-toggle label .switch-on, .on-off-toggle label .switch-off{
    width: 50%;
    z-index: 5;
    cursor: pointer;
    color: white;
}

.on-off-toggle label .off-color{
    color: gray;
}


.on-off-toggle label::after{
    position: absolute;
    right:0;
    left:50%;
    top:0;
    width: 50%;
    height: 100%;
    content: "";
    background: #6bcdb2;
    z-index: 1;

    transition: right .3s ease-in-out;
    -webkit-transition: right .3s ease-in-out;
    transition: left .3s ease-in-out;
    -webkit-transition: left .3s ease-in-out;    

}

.on-off-toggle input[type="checkbox"]:checked ~ label::after{
    position: absolute;
    left:0;
    right: 50%;
    top:0;
    width: 50%;
    height: 100%;
    content: "";
    background: #6bcdb2;
    z-index: 1;
}



.on-off-toggle.on-off-toggle-rtl label::after{
    position: absolute;
    left:0 !important;
    right:50% !important;
    top:0;
    width: 50%;
    height: 100%;
    content: "";
    background: #6bcdb2;
    z-index: 1;

    transition: left .3s ease-in-out !important;
    -webkit-transition: left .3s ease-in-out !important;
    transition: right .3s ease-in-out !important;
    -webkit-transition: right .3s ease-in-out !important;    

}

.on-off-toggle.on-off-toggle-rtl input[type="checkbox"]:checked ~ label::after{
    position: absolute;
    right:0 !important;
    left: 50% !important;
    top:0;
    width: 50%;
    height: 100%;
    content: "";
    background: #6bcdb2;
    z-index: 1;
}