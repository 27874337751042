
  
  .is-loading .image ,.is-loading h2   ,.is-loading p, .is-loading .shine, .is-loading .circle {
      background: #eee;
      background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
      border-radius: 5px;
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;
    }
  
    .is-loading .image {

      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .is-loading .circle{
        width: 140px;
        height: 140px;
        border-radius: 50%;        
    }
  
    .is-loading .content {
        padding: 20px 0px;
    }

    .is-loading h2 {
      height: 30px;
    }

    .is-loading .shine {
        margin-bottom: 3px;
        height: 20px;
    }

    .is-loading .half {
        width: 50%;
    }
    .is-loading button {
        width: 50px;
        height:20px;
        border-radius: 5px;
    }    
  
    .is-loading p  {
      
    }
  
  
  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
  