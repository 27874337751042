/* Upload project Modal */
#root {
    position: relative;
    min-height: 100%;
    overflow: hidden;
    background-color: #fff;
}
.upload-modal{
    width: 70% !important;
    }
    .upload-text{
        text-align: center !important;
        font-size: 25px !important;
    }
    .course-name{
        color: #F39422;
    }
    .progressing-note{
        text-align: center;
    }
    .selectFile{
        margin-top: -11px !important;
        height: 34px !important;
    }
   .modal-header{
    font-size: 25px;
   }
   .modal-body {
    font-size: 23px;
    text-align: center;
}
.btn-primary{
    background-color: #F39422 !important;
    border-color: #F39422 !important;
    outline: none !important;
}
.btn-primary:hover{
    background-color: #F39422 !important;
    border-color: #F39422 !important;
    outline: none !important;
}
.modal-body > a {
    color:#F39422 !important;
    text-decoration: underline !important;
}
.modal-body > a:hover {
    color:#e28922  !important;
}
.gray-bordered{
    background-color: #ededed !important;
    border: none !important;
}
.gray-bordered > p {
    color: #7f7f7f !important;
}
.forgot-pass{
    color: #999 !important;
}

@media(max-width:400px)
{
    .gray-bordered > p {
        font-size: 19px !important;
    }
    #history-modal > .mmodal-dialog > .modal-content2 > .modal-header {
        font-size: 20px !important;
    }
    #history-modal > .mmodal-dialog > .modal-content2 > .modal-body > table > thead > tr > th{
        font-size: 15px !important;
        padding: 3px !important;
    }
    #history-modal > .mmodal-dialog > .modal-content2 > .modal-body > table > tr > td {
        font-size: 15px !important;
    }
}

@media (max-width: 800px){
    .switch-plan-message {
        font-size: 1.3rem !important;
    }

}